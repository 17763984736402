import {
  ChangeDetectorRef, Component, ElementRef, EventEmitter, KeyValueDiffer, KeyValueDiffers, OnInit, 
  HostListener, NgZone, ViewChild 
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import Compressor from 'compressorjs';
import {
  faAngleDown,
  faAngleUp,
  faPlayCircle,
  faPaperclip,
  faCircleNotch,
  faCamera,
  faTrash,
  faPencilAlt,
  faPlus,
  faPaperPlane,
  faCheck,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Console } from 'console';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { PhotoPreviewComponent } from '../../surveys/photo-preview/photo-preview.component';
import { FinalReportHeaderComponent } from '../final-report-header/final-report-header.component';

import { CreateMRRFindingDialogComponent } from '../create-mrr-finding-dialog/create-mrr-finding-dialog.component';
import { MrrHeaderDialogComponent } from '../mrr-header-dialog/mrr-header-dialog.component';
import { PhotoCarouselComponent } from '../../surveys/photo-carousel/photo-carousel.component';
import { LoaderService } from '../../common-services/loader.service';
import { MrrStoreSignoffCommentsDialogComponent } from '../mrr-store-signoff-comments-dialog/mrr-store-signoff-comments-dialog.component';
import { SharedFunctionService } from '../../shared/shared-service/shared-function.service';
import { User } from '../../models/user';
import { MrrSurveysService } from '../mrr-surveys.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-mrr-draft-details',
  templateUrl: './mrr-draft-details.component.html',
  styleUrls: ['./mrr-draft-details.component.scss'],
})
export class MrrDraftDetailsComponent implements OnInit {
  @ViewChild('printSurvey') printSurvey: ElementRef;

  private differ: KeyValueDiffer<string, any>;

  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  faPlayCircle = faPlayCircle;

  faCircleNotch = faCircleNotch;

  faCamera = faCamera;

  faTrash = faTrash;

  faPencilAlt = faPencilAlt;

  faPlus = faPlus;

  faPaperPlane = faPaperPlane;

  faCheck = faCheck;

  faSave = faSave;

  faPaperclip = faPaperclip;

  allExpandState = true;

  amberResponses = 0;

  canEdit = false;

  greenResponses = 0;

  naResponses = 0;

  noResponses = 0;

  openItems = [];

  redResponses = 0;

  objDiffer = {};

  printVersion = '';

  user: User = {};

  saved = false;

  saving = false;

  surveyDetails: any = {};

  surveyID = '';

  surveyResponses = {};

  surveys = [];

  surveyStates = [];

  yesResponses = 0;

  today = moment();

  finalreport = {};

  finalratingFlag: boolean = false;

  uploading: boolean = false;

  photos = [];

  signoffDone: boolean = false;

  signedOffDetails = false;

  signedOffByAnyRole: boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private differs: KeyValueDiffers,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private zone: NgZone,
    private loader: LoaderService,
    private sharedFunction: SharedFunctionService,
    private mrrService : MrrSurveysService,
    private surveyService : SurveysService
  ) { }

  ngOnInit(): void {
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    this.user = this.csaAuth.user;
    this.differ = this.differs.find(this.surveyDetails).create();
    const changes = this.differ.diff(this.surveyDetails);
    if (changes) {
      changes.forEachChangedItem((r) => {});
      //   changes.forEachAddedItem(function (r) {});
      //   changes.forEachRemovedItem(function (r) {});
    }

    this.surveyDetails['surveyState'] = 'Start';
    console.log(this);
    window.addEventListener('scroll', this.scroll, true);
    this.cd.detectChanges();

    this.surveyService.getSurveyById(this.surveyID).subscribe(
      (surveysData) => {
        console.log(surveysData);
        if (surveysData['status'] == 500) {
          this.router.navigate(['/error']);
        }
        this.surveyDetails = surveysData;
        // this.getNotes();

        this.signOffAbilityCheck();
        this.getMRRFinalReport();
        this.getSurveyState();

        if (
          this.user['divisionRoles'][this.user['role']]
            >= (this.user['divisionRoles']['Admin']
              && this.surveyDetails['status'] == 'open')
          || (this.user['divisionRoles']['Admin']
            && this.surveyDetails['status'] == 'active')
        ) {
          this.canEdit = true;
        }

        this.checkSignOffDetailVisibility();

        // this.canEdit = true;

        this.differ = this.differs.find(this.surveyDetails).create();

        const changes = this.differ.diff(this.surveyDetails);
        if (changes) {
          changes.forEachChangedItem((r) => {});
          changes.forEachAddedItem((r) => {});
          changes.forEachRemovedItem((r) => {});
        }

        this.surveyDetails['surveyState'] = 'Start';
        console.log(this);
        window.addEventListener('scroll', this.scroll, true);
        this.cd.detectChanges();
      },
      (error) => {
        this.router.navigate(['/error', 'unauthorised']);
      }
    );
  }

  checkSignOffDetailVisibility() {
    if (this.sharedFunction.isAssessmentSignedoff(this.surveyDetails)) {
      this.signedOffByAnyRole = true;
    }
  }

  signOffAbilityCheck() {
    if (this.surveyDetails['signOff']) {
      if (this.surveyDetails['signOff']['required']) {
        for (
          let i = 0;
          i < this.surveyDetails['signOff']['details'].length;
          i++
        ) {
          if (
            this.surveyDetails['signOff']['details'][i]['role']
            == this.user['role']
          ) {
            if (!this.surveyDetails['signOff']['details'][i]['signedOff']) {
              // this.signoffbyRole = true;
              console.log(
                'signOffAbilityCheck passed by ',
                this.surveyDetails['signOff']['details'][i]
              );
            } else {
              this.signoffDone = true;
            }
          } else if (this.surveyDetails['signOff']['details'][i]['signedOff']) {
            this.signoffDone = true;
          }
        }
      }
    }
  }

  getAssessmentDetails() {
    this.surveyService
      .getSurveyById(this.surveyID)
      .subscribe((surveysData) => {
        this.surveyDetails = surveysData;
        console.log('he form getSurveyById', this.surveyDetails['status']);
      });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  @ViewChild('saveButton', { static: false }) saveButton: ElementRef;

  scroll = (event): void => {
    // if (event.srcElement.scrollTop > 75) {
    //   this.saveButton["nativeElement"].classList.add("float-right");
    // } else {
    //   this.saveButton["nativeElement"].classList.remove("float-right");
    // }
  };

  OpenSignOffComments() {
    const dialogRef = this.dialog.open(MrrStoreSignoffCommentsDialogComponent, {
      data: {
        surveyDetails: this.surveyDetails,
      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: '60vh',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // UPDATE PAGE
      this.cd.detectChanges();
    });
  }

  editHeader(): void {
    if (this.finalreport['rating']) {
      this.surveyDetails['rating'] = this.finalreport['rating'];
    }
    const dialogRef = this.dialog.open(MrrHeaderDialogComponent, {
      data: {
        survey: this.surveyDetails,
      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // UPDATE PAGE
      console.log(result);
      this.getMRRFinalReport();
      // this.cd.detectChanges();
    });
  }

  updateFindings(edit, finding) {
    const dialogRef = this.dialog.open(CreateMRRFindingDialogComponent, {
      data: {
        // surveyId: this.surveyDetails['id']
        survey: this.surveyDetails,
        edit,
        finding,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result['edit']) {
        console.log(this.surveyDetails, 'edit', result['finding']);
        this.updateFinding(result['finding']);
      } else if (result['type'] == 'auto') {
        this.UnEditedFinding();
      } else if (result['type'] == 'delete') {
        this.deleteFinding(result['finding']);
      }
    });
  }

  createFinding(edit = false, finding = {}) {
    const dialogRef = this.dialog.open(CreateMRRFindingDialogComponent, {
      data: {
        // surveyId: this.surveyDetails['id']
        survey: this.surveyDetails,
        edit,
        finding,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result['type'] == 'final') {
        if (result['edit']) {
          console.log(this.surveyDetails, 'edit', result['finding']);
          this.updateFinding(result['finding']);
        } else {
          console.log('create', result['finding']);
          const createData = {};
          const question = {};
          createData['questions'] = [];
          createData['id'] = this.surveyDetails['id'];
          createData['questions'].push(result['finding']);

          console.log('create Data', createData);
    
          const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(createData, ['questions']);
          this.mrrService.createFinding(requestJSONPayload).subscribe(
            (reply) => {
              if (reply['status'] == 200) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'Finding Saved',
                    message: 'Your finding has been saved successfully.',
                  },
                  disableClose: true,
                  backdropClass: 'dialog-backdrop',
                });
              } else {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'Cannot Save Finding',
                    message:
                    `Please email ${this.user['supportEmail']} for support`,
                  },
                  disableClose: true,
                  backdropClass: 'dialog-backdrop',
                });
              }
              this.ngOnInit();
            },
            (err) => {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Cannot Save Finding',
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
              console.log(err);
            }
          );
        }
      } else if (result['type'] == 'delete') {
        for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
          if (
            this.surveyDetails['questions'][i]['question_id']
            == finding['question_id']
          ) {
            this.surveyDetails['questions'].splice(i, 1);
            this.surveyDetails['responses'].splice(i, 1);
          }
        }
      }
      console.log(this.surveyDetails);
      this.cd.detectChanges();
    });
  }
  
  UnEditedFinding() {
    const updateData = {};
    const question = {};
    updateData['questions'] = [];
    updateData['id'] = this.surveyDetails['id'];
    updateData['questions'].push(question);
    this.ngOnInit();
  }

  updateFinding(finding) {
    const updateData = {};
    const question = {};
    updateData['questions'] = [];
    updateData['id'] = this.surveyDetails['id'];
    question['question_id'] = finding['question_id'];
    this.differ = this.differs.find(finding).create();
    const changes = this.differ.diff(finding);
    if (changes) {
      changes.forEachChangedItem((r) => {
        question[r.key] = r.currentValue;
      });
      changes.forEachAddedItem((r) => {
        question[r.key] = r.currentValue;
      });
    }

    updateData['questions'].push(question);

    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(updateData, ['questions']);
    this.mrrService.updateFinding(requestJSONPayload).subscribe(
      (reply) => {
        if (reply['status'] == 200) {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Finding updated',
              message: 'Your finding has been saved successfully.',
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          }); 
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Cannot update Finding',
              message:
                `Please email ${this.user['supportEmail']} for support`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        }
        this.ngOnInit();
      },
      (err) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot update Finding',
            message:
              `Please email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(err);
      }
    );
  }

  availableToString(value) {
    switch (value) {
      case 'store':
        return 'Store Users';
      case 'above-store':
        return 'Above Store Users';
      default:
        return '';
    }
  }

  deleteFinding(finding) {
    const message = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Are you sure you want to delete this finding? This cannot be un-done.',
        closeText: 'Delete',
        cancelText: 'Cancel',
        maxWidth: '90vw',
      },
      backdropClass: 'dialog-backdrop',
    });

    message.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        const deleteData = {};
        const question = {};

        deleteData['questions'] = [];
        deleteData['id'] = this.surveyDetails['id'];
        question['question_id'] = finding['question_id'];
        deleteData['status'] = this.surveyDetails['status'];
        deleteData['questions'].push(question);

        const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(deleteData, ['questions']);
        this.mrrService.deleteFinding(requestJSONPayload).subscribe(

          (reply) => {
            if (reply['status'] == 200) {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Finding deleted',
                  message: 'Your finding has been deleted successfully.',
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Cannot delete Finding',
                  message:
                  `Please email ${this.user['supportEmail']} for support`,
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
            }
            this.ngOnInit();
          },
          (err) => {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Cannot delete Finding',
                message:
                  `Please email ${this.user['supportEmail']} for support`,
              },
              disableClose: true,
              backdropClass: 'dialog-backdrop',
            });
            console.log(err);
          }
        );

        for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
          if (
            this.surveyDetails['questions'][i]['question_id']
            == finding['question_id']
          ) {
            this.surveyDetails['questions'].splice(i, 1);
            this.surveyDetails['responses'].splice(i, 1);
          }
        }
      }
    });
  }

  setOpened(itemIndex) {
    this.openItems.push(itemIndex);
  }

  setClosed(itemIndex) {
    for (let i = 0; i < this.openItems.length; i++) {
      if (this.openItems[i] === itemIndex) {
        this.openItems.splice(i, 1);
      }
    }
  }

  splitByCaps(value): string {
    if (
      this.user['division'] == 'DC'
      && (value == 'Store' || value == 'MultiStore')
    ) {
      if (value == 'Store') {
        return 'DCs';
      }
      if (value == 'MultiStore') {
        return 'Multi DC Users';
      }
    }

    if (value == 'Admin' || value == 'NationalView' || value == 'MultiStore') {
      value += 'Users';
    } else {
      if (value == 'OperationManager') {
        value = 'ZoneOperationManager';
      } else if (value == 'StockLossPartner') {
        value = 'StoreLossPartner';
      }
      if (value.substr(-1) != 's') {
        value += 's';
      }
    }

    return value.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
  }

  viewPhoto(photoName, questionID, fileName) {
    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.surveyDetails['id']
      }-${
        questionID
      }-${
        this.user['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewPhoto(photoName, questionID, fileName);
            }
          });
        } else {
          const photoDialog = this.dialog.open(PhotoPreviewComponent, {
            data: {
              photoURL: photoData[photo],
              photoName,
              showRemove: true,
              documentType: 'Evidence',
            },
            backdropClass: 'dialog-backdrop',
            minWidth: '70%',
            maxWidth: '90vw',
            height: 'auto',
          });
          photoDialog.afterClosed().subscribe((result) => {
            if (result == 'delete') this.removePhoto(photoData, questionID, photo);
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
            `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewPhoto(photoName, questionID, fileName);
          }
        });
      }
    );
  }

  removePhoto(src, question, fileName) {
    this.surveyService
      .removePhoto(fileName, src, this.surveyDetails['id'], question)
      .subscribe(
        (response) => {
          this.removePhotoFromQ(question, fileName);
          this.cd.detectChanges();
          // this.saveForm(question);
        },
        (error) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Remove File",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
  }

  removePhotoFromQ(question, fileName) {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      if (this.surveyDetails['questions'][i]['question_id'] == question) {
        for (
          let j = 0;
          j < this.surveyDetails['questions'][i]['photos'].length;
          j++
        ) {
          if (
            this.surveyDetails['questions'][i]['photos'][j]['photoName']
            == fileName
          ) {
            this.surveyDetails['questions'][i]['photos'].splice(j, 1);
            setTimeout(() => {
              this.surveyDetails['questions'][i]['uploading'] = false;
            }, 10);
          } else if (
            this.surveyDetails['questions'][i]['photos'][j]['fileName']
            == fileName
          ) {
            this.surveyDetails['questions'][i]['photos'].splice(j, 1);
            setTimeout(() => {
              this.surveyDetails['questions'][i]['uploading'] = false;
            }, 10);
          }
        }
      }
    }
  }

  // eslint-disable-next-line default-param-last
  getAllPhotos(questionID: string, photoIndex = 0, fileName?: string) {
    const evidence = [];
    let questionIndex = 0;
    let sectionHeadingtotalIndex;
    for (let i = 0; i < this.surveyDetails['responses'].length; i++) {
      const response = this.surveyDetails['responses'][i];
      for (let j = 0; j < this.surveyDetails['sectionHeading'].length; j++) {
        if (
          this.surveyDetails['questions'][i]['sectionHeading']
          == this.surveyDetails['sectionHeading'][j]
        ) {
          sectionHeadingtotalIndex = j + 1;
        }
      }
      if (response['photos'] && response['photos'].length > 0) {
        const responsePhotos = {
          photos: [],
          questionTitle: this.surveyDetails['questions'][i]['title'],
          questionID,
          sectionHeading: this.surveyDetails['questions'][i]['sectionHeading'],
          totalIndex: i + 1,
          sectionHeadingtotalIndex,
          response,
        };

        if (questionID == response['question_id']) {
          questionIndex = evidence.length;
        }

        for (let j = 0; j < response['photos'].length; j++) {
          if (!response['photos'][j]['fileName']) {
            response['photos'][j]['fileName'] = `${this.surveyDetails['id']
            }-${
              response['question_id']
            }-${
              this.user['storeID']
            }-${
              response['photos'][j]['photoName']}`;
          }

          if (
            fileName
            == response['photos'][j]['fileName']
              .replace(/\s/g, '-')
              .replace(/\.[^.]*$/, '')
          ) {
            photoIndex = j;
          }

          responsePhotos.photos.push({
            photoName: response['photos'][j]['photoName'],
            fileName: response['photos'][j]['fileName'],
          });
        }

        evidence.push(responsePhotos);
      }
      this.surveyDetails['responses'][i] = response;
    }

    const photoDialog = this.dialog.open(PhotoCarouselComponent, {
      data: {
        evidence,
        evidenceIndex: questionIndex,
        photoIndex,
        totalNumber: this.surveyDetails['questions'].length,
        sectiontotalNumber: this.surveyDetails['sectionHeading'].length,
        showRemove: true,
        type: 'mrrFindings',
      },
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto',
    });
    photoDialog.afterClosed().subscribe((result) => {
      if (result.status == 'delete') {
        console.log(result);
        this.removePhoto(result.src, result.questionID, result.fileName);
      }
    });
  }

  getNotes() {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      this.surveyDetails['questions'][i]['uploading'] = false;
      for (let j = 0; j < this.surveyDetails['responses'].length; j++) {
        if (
          this.surveyDetails['responses'][j]['question_id']
          == this.surveyDetails['questions'][i]['question_id']
        ) {
          this.surveyDetails['questions'][i]['notes'] = this.surveyDetails['responses'][j]['notes'];
          this.surveyDetails['questions'][i]['response'] = this.surveyDetails['responses'][j]['response'];
          // this.surveyDetails["questions"][i]["actions"] =
          //   this.surveyDetails["responses"][j]["actions"];

          if (!this.surveyDetails['responses'][j]['actions']) {
            this.surveyDetails['questions'][i]['actions'] = [];
            this.surveyDetails['responses'][j]['actions'] = [];
          } else {
            this.surveyDetails['questions'][i]['actions'] = this.surveyDetails['responses'][j]['actions'];
          }

          this.surveyDetails['questions'][i]['photos'] = this.surveyDetails['responses'][j]['photos'];
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
          this.surveyDetails['questions'][i]['reviewedFrom'] = this.surveyDetails['responses'][i]['reviewedFrom'];
          this.surveyDetails['questions'][i]['reviewedTo'] = this.surveyDetails['responses'][i]['reviewedTo'];
        }
      }
    }
  }

  getSurveyState() {
    const completion = this.surveyDetails['completion'];
    if (this.surveyDetails['status'] == 'active') {
      this.surveyDetails['surveyState'] = 'Start';
    } else if (this.surveyDetails['status'] == 'started') {
      this.surveyDetails['surveyState'] = 'Continue';
    } else if (this.surveyDetails['status'] == 'submitted') {
      this.surveyDetails['surveyState'] = 'Submitted';
    } else if (this.surveyDetails['status'] == 'closed') {
      this.surveyDetails['surveyState'] = 'Closed';
    }
  }

  uploadPhoto($event, findingID, survey) {
    // let questionIndex =2;
    const questionIndex = this.surveyDetails['questions'].findIndex(
      (item) => item['question_id'] === findingID
    );
    this.surveyDetails['questions'][questionIndex]['uploading'] = true;
    const photoName = $event.target.files[0].name;
    const goodPhotoType = $event.target.files[0].name
      .toLowerCase()
      .match(/\.(pdf|jpg|png|gif|tiff|jpeg|bmp|raw)/g);

    const PDFTest = $event.target.files[0].name.toLowerCase().match(/\.(pdf)/g);
    if (goodPhotoType == null) {
      this.displayUploadError(
        'This file is not an image or PDF',
        'Please select an image or PDF file to attach.',
        questionIndex
      );
    } else if (PDFTest == null) {
      this.compressPhoto($event.target.files[0], findingID).subscribe(
        (compressedPhoto) => {
          if (typeof compressedPhoto === 'undefined') {
            this.displayUploadError(
              'Error Compressing File',
              `Please try a different file or email ${ 
                this.user['supportEmail']
              } for support`,
              questionIndex
            );
          } else if (compressedPhoto['size'] > 10000000) {
            this.displayUploadError(
              'This File Is Too Big',
              'Please upload a smaller file',
              questionIndex
            );
          } else {
            const action = this.surveyService
              .uploadPhoto(
                compressedPhoto,
                survey,
                findingID,
                this.surveyDetails['storeID'],
                `${compressedPhoto['size']}`
              )
              .subscribe(
                (response) => {
                  if (!response[0] || response[0]['status'] != 200) {
                    let message;
                    if (response[0]['status'] != 200) {
                      message = `There was an error when sending your file. Please email ${
                        this.user['supportEmail']
                      } for support`;
                    } else {
                      message = `No response from server. Please email ${
                        this.user['supportEmail']
                      } for support`;
                    }
                    this.displayUploadError(
                      "Couldn't Add File",
                      message,
                      questionIndex
                    );
                  } else if (response[0]['message'] == 'OK') {
                    this.getPhotosThisQuestion(questionIndex, {
                      photoName: response[0]['name'],
                      fileName: response[0]['fileID'],
                      fileSize: compressedPhoto['size'],
                    });
                  }
                },
                (error) => {
                  this.displayUploadError(
                    "Couldn't Add File",
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                    questionIndex
                  );
                }
              );
          }
        }
      );
    } else if ($event.target.files[0]['size'] > 10000000) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'This File Is Too Big',
          message: 'Please upload a smaller file',
          closeText: 'Ok',
        },
        backdropClass: 'dialog-backdrop',
      });
      setTimeout(() => {
        this.surveyDetails['questions'][questionIndex]['uploading'] = false;
      }, 10);
    } else {
      const action = this.surveyService
        .uploadPhoto(
          $event.target.files[0],
          survey,
          findingID,
          this.surveyDetails['storeID'],
          $event.target.files[0]['size']
        )
        .subscribe(
          (response) => {
            if (!response[0] || response[0]['status'] != 200) {
              let message;
              if (response[0]['status'] != 200) {
                message = `There was an error when sending your file. Please email ${
                  this.user['supportEmail']
                } for support`;
              } else {
                message = `No response from server. Please email ${
                  this.user['supportEmail']
                } for support`;
              }
              this.displayUploadError(
                "Couldn't Add File",
                message,
                questionIndex
              );
            } else if (response[0]['message'] == 'OK') {
              this.getPhotosThisQuestion(questionIndex, {
                photoName: response[0]['name'],
                fileName: response[0]['fileID'],
                fileSize: $event.target.files[0]['size'],
              });
              this.cd.detectChanges();
            }
          },
          (error) => {
            this.displayUploadError(
              "Couldn't Add File",
              `Please email ${this.user['supportEmail']} for support`,
              questionIndex
            );
          }
        );
    }
  }

  compressPhoto(file, question) {
    const emitter = new EventEmitter<Blob>();

    const compression = new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: 1000000,
      success(result) {
        emitter.emit(result);
      },
      error(err) {
        emitter.emit();
      },
    });
    return emitter.asObservable();
  }

  displayUploadError(heading, message, questionIndex) {
    this.dialog.open(MessagesComponent, {
      data: {
        heading,
        message,
        closeText: 'Ok',
      },
      backdropClass: 'dialog-backdrop',
    });
    setTimeout(() => {
      this.surveyDetails['questions'][questionIndex]['uploading'] = false;
    }, 10);
  }

  getPhotosThisQuestion(questionIndex, photo) {
    // this.saveForm(this.surveyDetails['questions'][questionIndex]['id']);

    setTimeout(() => {
      if (!this.surveyDetails['questions'][questionIndex]['photos']) {
        this.surveyDetails['questions'][questionIndex]['photos'] = [];
        // this.surveyDetails["responses"][questionIndex]["photos"] = [];
      }
      this.surveyDetails['questions'][questionIndex]['photos'].push(photo);
      // this.surveyDetails["responses"][questionIndex]["photos"].push(photo);
      this.surveyDetails['questions'][questionIndex]['uploading'] = false;
      // this.addEvidence()
    }, 10);
    this.cd.detectChanges();
  }

  addEvidence() {
    // same api is used for creating finding, add Evidence  and add header detailes in MRR

    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(this.surveyDetails, ['questions']);
    this.mrrService.createFinding(requestJSONPayload).subscribe(
      (reply) => {
        this.ngOnInit();
      },
      (err) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot Save Evidence',
            message:
              `Please email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(err);
      }
    );
  }

  publishMrr() {
    // If the MRR is a draft, publishing will update the status and make it available to the store. If the MRR was already published, this will update the existing response with new/modified findings. If a finding is removed that previously contained evidence/actions, these will also need to be individually deleted.
    const dialogRef = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Submitting this Material Risk Review  will make it available to Store Manager. Do you wish to proceed?',
        closeText: 'Submit',
        cancelText: 'Cancel',
      },
      backdropClass: 'dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result == 'logout') {
        if (this.surveyDetails['filterName'] == 'mrr') {
          const mrrData = {};
          mrrData['id'] = this.surveyDetails['id'];
          mrrData['domain'] = this.user['domain'];
          this.mrrService.mrrActivateEmailTrigger(mrrData).subscribe(
            (response) => {
              console.log(response);
              console.log(response['status']);
              if (response['status'] == 200) {
                this.router.navigate(['thankyou']);
              }
              if (response['status'] != 200) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: "Couldn't Activate Assessment",
                    message:
                      `Please email ${ 
                        this.user['supportEmail']
                      } for support`,
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
              }
            },
            (error) => {
              console.log(error);
              this.router.navigate(['thankyou']);
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't send email",
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          );
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Activate Assessment",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      }
    });
  }

  saveForm() {
    this.saving = true;
    const data = {};

    data['id'] = this.surveyDetails['id'];
    // data["status"] = "started";
    data['modifiedBy'] = this.user['email'];

    let answeredQs = 0;

    this.surveyDetails['responses'].forEach((response) => {
      if (response['actions']) {
        answeredQs++;
      }
    });

    if (answeredQs != 0) {
      this.surveyDetails['completion'] = answeredQs / this.surveyDetails['questions'].length;
    } else {
      this.surveyDetails['completion'] = 0;
    }

    const changes = this.differ.diff(this.surveyDetails);
    if (changes) {
      changes.forEachChangedItem((r) => {
        data[r.key] = r.currentValue;
      });
      changes.forEachAddedItem((r) => {
        data[r.key] = r.currentValue;
      });
      changes.forEachRemovedItem((r) => {
        data[r.key] = r.currentValue;
      });
    }

    data['responses'] = [];

    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      const response = {};

      const objDiffer = this.objDiffer[this.surveyDetails['responses'][i]['question_id']];
      const objChanges = objDiffer.diff(this.surveyDetails['responses'][i]);
      if (objChanges) {
        this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
        response['question_id'] = this.surveyDetails['questions'][i]['question_id'];
        objChanges.forEachChangedItem((r) => {
          if (
            r.key != 'autoSavedAction'
            && r.key != 'photos'
            && r.key != 'actions'
          ) {
            response[r.key] = r.currentValue;
          }
        });
        objChanges.forEachAddedItem((r) => {
          if (
            r.key != 'autoSavedAction'
            && r.key != 'photos'
            && r.key != 'actions'
          ) {
            response[r.key] = r.currentValue;
          }
        });
        objChanges.forEachRemovedItem((r) => {
          if (
            r.key != 'autoSavedAction'
            && r.key != 'photos'
            && r.key != 'actions'
          ) {
            response[r.key] = r.currentValue;
          }
        });

        data['responses'].push(response);
      }
    }

    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['responses']);
  
    this.surveyService.saveSurvey(requestJSONPayload).subscribe(    
      (response) => {
        if (response['status'] == 400) {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Save Assessment",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        } else {
          setTimeout(() => (this.saved = true));
        }
      },
      (error) => {
        console.log(error);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Network Error',
            message:
              `Please check that you have Wifi or 4g signal, and email ${ 
                this.user['supportEmail']
              } for support if the issue persists`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }

  finalRate(edit, finalreport): void {
    if (this.surveyDetails['rating']) {
      finalreport['rating'] = this.surveyDetails['rating'];
    }
    const dialogRef = this.dialog.open(FinalReportHeaderComponent, {
      data: {
        surveyDetails: this.surveyDetails,
        finalreport,
      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result['type'] == 'final') {
        this.getMRRFinalReport();
        this.finalreport = result['finalReport'];
      } else if (edit) {
        this.finalreport = {};
        this.getMRRFinalReport();
      } else {
        this.getMRRFinalReport();
      }
      // UPDATE PAGE
      console.log('FinalReportHeaderComponent', result, this.finalreport);
      this.cd.detectChanges();
    });
  }

  getMRRFinalReport() {
    // call getMRRFinalReport api
    this.surveyService.getSurveyById(this.surveyID).subscribe((surveysData) => {
      console.log(surveysData);
      if (surveysData['status'] == 500) {
        this.router.navigate(['/error']);
      }
      this.surveyDetails = surveysData;
      this.getNotes();
      // adding details to final headerpage
      if (this.surveyDetails['finalReportDate']) {
        this.finalreport['finalReportDate'] = this.surveyDetails['finalReportDate'];
        this.finalreport['summaryFindings'] = this.surveyDetails['summaryFindings'];
        this.finalreport['rating'] = this.surveyDetails['rating'];
        this.finalreport['id'] = this.surveyDetails['id'];
        if (this.surveyDetails['files']) {
          if (this.surveyDetails['files']['final']) this.finalreport['finalReportPhoto'] = this.surveyDetails['files']['final'];
        }
      }
      // adding details to final headerpage
    });
  }

  setOpenedfinal() {
    this.finalratingFlag = true;
  }

  setClosedfinal() {
    this.finalratingFlag = false;
  }

  uploadDoc($event, question, survey) {
    this.uploading = true;
    const photoName = $event.target.files[0].name;
    const goodPhotoType = $event.target.files[0].name
      .toLowerCase()
      .match(/\.(pdf|jpg|png|gif|tiff|jpeg|bmp|raw)/g);

    const PDFTest = $event.target.files[0].name.toLowerCase().match(/\.(pdf)/g);
    if (goodPhotoType == null) {
      this.displayUploadErrorDoc(
        'This file is not an image or PDF',
        'Please select an image or PDF file to attach.'
      );
    } else if (PDFTest == null) {
      this.compressPhoto($event.target.files[0], question).subscribe(
        (compressedPhoto) => {
          if (typeof compressedPhoto === 'undefined') {
            this.displayUploadErrorDoc(
              'Error Compressing File',
              `Please try a different file or email ${ 
                this.user['supportEmail']
              } for support`
            );
          } else if (compressedPhoto['size'] > 10000000) {
            this.displayUploadErrorDoc(
              'This File Is Too Big',
              'Please upload a smaller file'
            );
          } else {
            const action = this.surveyService
              .uploadPhoto(
                compressedPhoto,
                survey,
                question,
                this.surveyDetails['storeID'],
                `${compressedPhoto['size']}`
              )
              .subscribe(
                (response) => {
                  if (!response[0] || response[0]['status'] != 200) {
                    let message;
                    if (response[0]['status'] != 200) {
                      message = `There was an error when sending your file. Please email ${
                        this.user['supportEmail']
                      } for support`;
                    } else {
                      message = `No response from server. Please email ${
                        this.user['supportEmail']
                      } for support`;
                    }
                    this.displayUploadErrorDoc("Couldn't Add File", message);
                  } else if (response[0]['message'] == 'OK') {
                    this.getDocThisQuestion({
                      photoName: response[0]['name'],
                      fileName: response[0]['fileID'],
                      fileSize: compressedPhoto['size'],
                    });
                  }
                },
                (error) => {
                  this.displayUploadErrorDoc(
                    "Couldn't Add File",
                    `Please email ${this.user['supportEmail']} for support`
                  );
                }
              );
          }
        }
      );
    } else if ($event.target.files[0]['size'] > 10000000) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'This File Is Too Big',
          message: 'Please upload a smaller file',
          closeText: 'Ok',
        },
        backdropClass: 'dialog-backdrop',
      });
      setTimeout(() => {
        this.uploading = false;
      }, 10);
    } else {
      const action = this.surveyService
        .uploadPhoto(
          $event.target.files[0],
          survey,
          question,
          this.surveyDetails['storeID'],
          $event.target.files[0]['size']
        )
        .subscribe(
          (response) => {
            if (!response[0] || response[0]['status'] != 200) {
              let message;
              if (response[0]['status'] != 200) {
                message = `There was an error when sending your file. Please email ${
                  this.user['supportEmail']
                } for support`;
              } else {
                message = `No response from server. Please email ${
                  this.user['supportEmail']
                } for support`;
              }
              this.displayUploadErrorDoc("Couldn't Add File", message);
            } else {
              this.getDocThisQuestion({
                photoName: response[0]['name'],
                fileName: response[0]['fileID'],
                fileSize: $event.target.files[0]['size'],
              });
              this.cd.detectChanges();
            }
          },
          (error) => {
            this.displayUploadErrorDoc(
              "Couldn't Add File",
              `Please email ${this.user['supportEmail']} for support`
            );
          }
        );
    }
  }

  displayUploadErrorDoc(heading, message) {
    this.dialog.open(MessagesComponent, {
      data: {
        heading,
        message,
        closeText: 'Ok',
      },
      backdropClass: 'dialog-backdrop',
    });
    setTimeout(() => {
      this.uploading = false;
    }, 10);
  }

  getDocThisQuestion(photo) {
    setTimeout(() => {
      if (!this.finalreport['finalReportPhoto']) {
        this.finalreport['finalReportPhoto'] = [];
        // this.surveyDetails["responses"][questionIndex]["photos"] = [];
      }
      // this.photos.push(photo);
      this.finalreport['finalReportPhoto'].push(photo);
      this.uploading = false;
    }, 10);
    this.cd.detectChanges();
  }

  viewfinalDoc(photoName, question, fileName, showRemove) {
    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.surveyDetails['id']
      }-${
        question
      }-${
        this.surveyDetails['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        console.log(photoData);
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewPhoto(photoName, question, fileName);
            }
          });
        } else if (Object.keys(photoData).length > 0) {
          this.zone.run(() => {
            const photoDialog = this.dialog.open(PhotoPreviewComponent, {
              data: {
                photoURL: photoData[photo],
                photoName,
                showRemove,
                documentType: 'Final Report',
              },
              backdropClass: 'dialog-backdrop',
              minWidth: '70%',
              maxWidth: '90vw',
              height: 'auto',
            });

            photoDialog.afterClosed().subscribe((result) => {
              if (result == 'delete') this.removeDoc(photoData, question, photo);
            });
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
            `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewPhoto(photoName, question, fileName);
          }
        });
      }
    );
  }

  removeDoc(src, question, photo) {
    this.mrrService
      .removeDoc(photo, src, this.surveyDetails['id'], question)
      .subscribe(
        (response) => {
          this.removeDocFromQ(photo);
          this.cd.detectChanges();
        },
        (error) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Remove File",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
  }

  removeDocFromQ(fileName) {
    for (let j = 0; j < this.finalreport['finalReportPhoto'].length; j++) {
      if (
        this.finalreport['finalReportPhoto'][j]['photoName'] == fileName
        || this.finalreport['finalReportPhoto'][j]['fileName'] == fileName
      ) {
        // this.photos.splice(j, 1);
        this.finalreport['finalReportPhoto'].splice(j, 1);
        setTimeout(() => {
          this.uploading = false;
        }, 10);
      }
    }
  }
}
