import {
  Component, Inject, Injectable, OnInit, ChangeDetectorRef 
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AnyMxRecord } from 'dns';
import {

  faAngleDown,
  faAngleUp,
  faTimes,

} from '@fortawesome/free-solid-svg-icons';
import {
  NgForm, NgModel, FormControl, FormGroup, Validators, AbstractControl 
} from '@angular/forms';
import * as moment from 'moment';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { RoleFieldToDisplayStringPipe } from 'src/app/custom-pipes/roleFieldToDisplayString.pipe';
import { SharedFunctionService } from 'src/app/shared/shared-service/shared-function.service';
import { DrillDownFilterPipe } from '../../custom-pipes/drill-down-filter.pipe';
import { MrrVisitNote } from '../../models/mrr-visit-note';
import { CsaAuthService } from '../../auth/csa-auth.service';

@Component({
  selector: 'app-visit-notes-dialog',
  templateUrl: './visit-notes-dialog.component.html',
  styleUrls: ['./visit-notes-dialog.component.scss']
})
export class VisitNotesDialogComponent implements OnInit {
  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  mrrForm: FormGroup;

  confirmText = 'Close';

  idleState = '';

  notes:any;

  faTimes = faTimes;

  surveyDetails: any;

  status: any;

  user: object;

  isVisitNotesExpanded: boolean = false;

  allExpandState: boolean = true; // expansion panel open and close param

  viewNotesAvailable: boolean = false;

  constructor(
    private csaAuthService: CsaAuthService,
    private cd: ChangeDetectorRef,
    private RoleFieldToDisplayStringPipe : RoleFieldToDisplayStringPipe,
    private sharedFunction: SharedFunctionService,
    private idle: Idle,
    private dialogRef: MatDialogRef<VisitNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    if (data['closeText']) {
      this.confirmText = data['closeText'];
    }

    if (data.message.indexOf('@') == -1) {
      data.message = data.message.replace('https://', '').replace('email', 'visit');
    }
  }

  ngOnInit(): void {
    this.user = this.csaAuthService.user;
    this.status = this.data.surveyDetails['status'];
    this.surveyDetails = this.data.surveyDetails['visitNotes'];
    this.checkVisitNotesVisibility();
    this.mrrForm = new FormGroup({
      notes: new FormControl('', [
        Validators.required,
        this.sharedFunction.emptyStringValidator.bind(this)
      ]),
      
    });
  }

  checkVisitNotesVisibility() {
    if (this.sharedFunction.isVisitNoteAdded(this.data.surveyDetails)) {
      this.viewNotesAvailable = true;
    }
  }

  closeMe() {
    this.dialogRef.close();
  }

  public cancel(navigateRef) {
    if (navigateRef) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close('continue');
    }
  }

  visitNotesExpansion() {
    if (this.isVisitNotesExpanded) {
      this.isVisitNotesExpanded = false;
    } else {
      this.isVisitNotesExpanded = true;
    }
  }

  /**
   * Saves notes.
   * 
   * @param value - Having the details of visit notes
   * auto updating visit notes.
   */
  saveNotes(value:MrrVisitNote) {
    if (value.notes) {
      this.notes = value.notes;
    }
  }

  /**
   * Sharing visit note details to parent component
   * closing dialog box after collecting visit note.
   *
   * @param value - The note.
   */
  sendVisitNote(value:MrrVisitNote) {
    this.notes = value.notes;
    this.dialogRef.close({ type: 'logout', notes: this.notes });
  }
}
