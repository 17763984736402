import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { faPlus, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { LoaderService } from '../../common-services/loader.service';
import { StateService } from '../../common-services/state.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CreateSurveyDialogComponent } from '../../surveys/create-survey-dialog/create-survey-dialog.component';
import { User } from '../../models/user';
import { RecurringSurveyService } from '../recurring-survey.service';

@Component({
  selector: 'app-more-recurring-surveys',
  templateUrl: './more-recurring-surveys.component.html',
  styleUrls: ['./more-recurring-surveys.component.scss'],
})
export class MoreRecurringSurveysComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faPlus = faPlus;

  faSortDown = faSortDown;

  allTemplates: any[] = [];

  date = moment();

  filter: string = '';

  frequencies: string[] = ['daily', 'weekly', 'monthly'];

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  selectedFrequency: string = 'All Frequencies';

  selectedGroup: number = 0;

  surveyGroups: string[];

  surveysToShow: any[] = [];

  tableColumns: string[];

  totalCount: number = 0;

  user: User;

  isLoading: boolean = false;

  constructor(
    private actRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private router: Router,
    private state: StateService,
    private surveyCompletionData: LoaderService,
    private recurringService : RecurringSurveyService,
    private surveyService: SurveysService
  ) {}

  ngOnInit(): void {
    this.user = this.csaAuth.user;

    if (this.state.state && this.state.origin == 'more-recurring-surveys') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }
    }

    if (this.user['role'] != 'Admin') {
      this.getInitialFilters();
    } else {
      this.getSurveysToShow(this.selectedFrequency);
    }
  }

  ngOnDestroy() {
    this.setState();
  }

  setState() {
    if (this.saveState) {
      this.state.origin = 'more-recurring-surveys';
      this.state.state = {
        allTemplates: this.allTemplates,
        filter: this.filter,
        hasNext: this.hasNext,
        index: this.index,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedFrequency: this.selectedFrequency,
        selectedGroup: this.selectedGroup,
        surveyGroups: this.surveyGroups,
        surveysToShow: this.surveysToShow,
        tableColumns: this.tableColumns,
        totalCount: this.totalCount,
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  resetPagination(): void {
    this.paginator.pageIndex = 0;
    this.index = 0;
    this.pageIndex = 0;
    this.pageSize = this.paginator.pageSize;
  }

  getInitialFilters() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.user['role'] == 'GroupManager') {
          if (this.user['division'] == 'Metro') {
            resolve(
              `&groupID=${ 
                this.user['groupNo']
              }&state=${
                this.user['state']}`
            );
          } else if (this.user['domain'] == 'WoolworthsNZ') {
            resolve(
              `&groupID=${ 
                this.user['groupNo']
              }&zone=${
                this.user['zone']
              }&state=${
                this.user['state']}`
            );
          } else {
            resolve(
              `&groupID=${ 
                this.user['groupNo']
              }&zone=${
                this.user['zone']
              }&state=${
                this.user['state']}`
            );
          }
        } else if (this.user['role'] == 'OperationManager') {
          if (this.user['division'] == 'Metro') {
            resolve(`&state=${this.user['state']}`);
          } else if (this.user['domain'] == 'WoolworthsNZ') {
            resolve(
              `&zone=${this.user['zone']}`
            );
          } else {
            resolve(
              `&zone=${this.user['zone']}&state=${this.user['state']}`
            );
          }
        } else if (this.user['role'] == 'StateManager') {
          resolve(`&state=${this.user['state']}`);
        } else if (this.user['role'] == 'RegionManager') {
          resolve(`&region=${this.user['region']}`);
        } else if (this.user['role'] == 'AreaManager') {
          resolve(
            `&area=${this.user['area']}&region=${this.user['region']}`
          );
        } else {
          resolve('');
        }
      });
    }).then((tempFilter: string) => {
      this.filter = tempFilter;
      this.getSurveysToShow('');
    });
  }

  ngAfterViewInit() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      label.innerHTML = `${String(this.index + 1)} to ${this.pageSize} of Many`;
    }
  }
 
  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.index = 0;
    this.pageIndex = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;
    this.getSurveysToShow(this.selectedFrequency);
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getSurveysToShow(this.selectedFrequency);
  }

  getSurveysToShow(frequency) {
    setTimeout(() => {
      this.isLoading = true;
      this.surveysToShow = [];
    }, 0);

    let search = '';
    let status = '';
    let frequencySearch = '';

    if (frequency == '' || frequency == 'All Frequencies') {
      search = '';
      this.selectedFrequency = 'All Frequencies';
    } else {
      this.selectedFrequency = frequency;
      search = `&frequency=${frequency}`;
    }

    if (this.user['role'] == 'Admin') {
      this.surveyGroups = ['Upcoming', 'Available', 'Closed'];
      if (this.selectedGroup == 2) {
        this.tableColumns = ['survey', '%Complete', 'nextPublishDate'];
        status = 'closed';
        frequencySearch = `recurring${search}`;
      } else if (this.selectedGroup == 1) {
        this.tableColumns = ['survey', '%Complete', 'nextPublishDate'];
        status = 'published';
        frequencySearch = `recurring${search}`;
      } else if (this.selectedGroup == 0) {
        this.tableColumns = ['survey', 'nextPublishDate'];
        status = 'active';
        frequencySearch = `recurring${search}`;
      }
    } else {
      this.surveyGroups = ['Daily', 'Weekly', 'Monthly', 'Closed'];
      if (this.selectedGroup == 3) {
        this.tableColumns = ['survey', '%Complete', 'dueDate'];
        status = 'closed';
        frequencySearch = 'recurring';
      } else if (this.selectedGroup == 2) {
        this.tableColumns = ['survey', '%Complete', 'dueDate'];
        status = this.csaAuth.isUserAboveStoreRole() ? 'published' : 'started&status=active';
        frequencySearch = 'recurring&frequency=monthly';
      } else if (this.selectedGroup == 1) {
        this.tableColumns = ['survey', '%Complete', 'dueDate'];
        status = this.csaAuth.isUserAboveStoreRole() ? 'published' : 'started&status=active';
        frequencySearch = 'recurring&frequency=weekly';
      } else if (this.selectedGroup == 0) {
        this.tableColumns = ['survey', '%Complete', 'dueDate'];
        status = this.csaAuth.isUserAboveStoreRole() ? 'published' : 'started&status=active';
        frequencySearch = 'recurring&frequency=daily';
      }
    }
    if (this.csaAuth.isUserStoreRole()) {
      this.surveyService
        .getStoreSurveys(
          `?status=${status}`
        + `&division=${this.csaAuth.user.division
        }&storeID=${this.csaAuth.user.storeID}`,
          `/${this.index}`,
          `/${this.pageSize}`,
          frequencySearch
        ).subscribe(
          (surveysData) => {
            this.surveysToShow = surveysData['surveys'];
            this.hasNext = surveysData['has_next'];
            this.getPagination();
            this.isLoading = false;
          }, 
          (error) => {
            this.isLoading = false;
          }
        );        
    } else {
      this.recurringService
        .getRecurringSurveys(
          this.user['division'] + this.filter,
          status,
          `/${this.index}`,
          `/${this.pageSize}`,
          frequencySearch
        ).subscribe((surveysData) => {
          this.surveysToShow = surveysData['surveys'];
          this.hasNext = surveysData['has_next'];
          this.getPagination();
          this.isLoading = false;
        }, (error) => {
          this.isLoading = false;
        });
    }
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  createSurvey() {
    const dialogRef = this.dialog.open(CreateSurveyDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      height: 'auto',
      data: { templateid: '', templateDivision: this.user['division'] },
    });
  }

  surveyDetails(surveyId, message: string, event) {
    event.stopPropagation();
    this.surveyCompletionData.changeMessage(message);
    const path = !this.csaAuth.isUserAboveStoreRole() ? '/store-landing/survey-details' : '/recurring-survey-details';
    this.router.navigate([path, surveyId]);
  }
}
