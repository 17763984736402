<div class="page-container">
  <h1 *ngIf="user['role'] !== 'StoreLossPartner' && user['role'] !== 'StoreLossManager'">Adhoc Assessments</h1>
  <h1 *ngIf="user['role'] === 'StoreLossPartner'|| user['role'] === 'StoreLossManager'">Store Loss Assessments</h1>
  
  <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" [(selectedIndex)]="selectedGroup"
        (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of surveyGroups">
          <table mat-table [dataSource]="surveysToShow">
            <ng-container matColumnDef="survey">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Assessment</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <h3>
                    <span *ngIf="survey['filterName']" class="infoTag">{{survey['filterName'] | uppercase}}</span>
                    &nbsp;{{survey.title.slice(0, 50) | unescape}}
                    <span *ngIf="survey.title.length > 50">...</span>
                  </h3>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="percentComplete">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Active Responses</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="float-up">
                  <h1>{{ survey['activeResponseCount']}}</h1>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="openDate">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Date Available</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div>
                  {{ survey['startDate'] | date :'dd MMM y'  }}   
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Create New</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="survey-button"
                  *ngIf="survey['targetRoles']?.indexOf(user['role']) > -1  && survey['status'] === 'published'">
                  <button class="csa-button-solid" (click)="createOnTheFlySurvey(survey['id'], $event)">
                    <span>
                      <fa-icon [icon]="faPlus"></fa-icon> Create
                    </span>
                    </button>
                </div>
                <div class="survey-button"
                  *ngIf="survey['status'] === 'closed' && survey['targetRoles']?.indexOf(user['role']) > -1 && !survey['final']">
                  <div class="light-grey medium-text" *ngIf="survey['daysToEdit'] == 1">
                    Last day to Edit Active Responses
                  </div>
                  <div class="light-grey medium-text" *ngIf="survey['daysToEdit'] > 1">
                    {{survey['daysToEdit']}} Days to Edit Active Responses
                  </div>
                </div>
                <div class="light-grey medium-text" *ngIf="survey['final']">
                  Assessment Closed
                </div>
                <div class="survey-details-link survey-button"
                  *ngIf="(survey['targetRoles']?.indexOf(user['role']) == -1 || survey['status'] === 'active') && survey['status'] !== 'closed'">
                  Available to <span *ngFor="let role of survey['targetRoles']; let j = index">
                    {{splitByCaps(role)}}<span *ngIf="survey['targetRoles']?.length - 1 > j">,&nbsp;</span></span>
                </div>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns" class="survey-heading-line"></mat-header-row>
            <mat-row *matRowDef="let survey; columns: tableColumns;" (click)="saveState = true"
              [routerLink]="['/onthefly-survey-details', survey.id]" class="survey-line survey-line-link"></mat-row>
          </table>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>

          <h3 class="none-found-message" *ngIf="surveysToShow.length == 0 && !isLoading">
            There are no Available Adhoc Assessments. Watch this space to see Adhoc Assessments once they are available.
          </h3>
        </mat-tab>
      </mat-tab-group>


      <mat-paginator #paginator 
          [pageIndex]=pageIndex 
          [pageSize]=pageSize 
          [length]="totalCount"
          [pageSizeOptions]="[5, 10, 25, 100]" 
          [showFirstLastButtons]=false 
          (page)="getNext($event)">
      </mat-paginator>
    </div>
  </div>
</div>
