import { Component, OnDestroy } from '@angular/core';
import { Subject, take, takeUntil } from 'rxjs';
import { CsaAuthService } from '../auth/csa-auth.service';
import { ToDoListService, toDoListData } from './to-do-list.service';

@Component({
  selector: 'app-to-do-list',
  templateUrl: './to-do-list.component.html',
  styleUrls: ['./to-do-list.component.scss']
})
export class ToDoListComponent implements OnDestroy {
  public toDoListAvailable: toDoListData;

  public toDoListThisWeek: toDoListData;

  public toDoListNextWeek: toDoListData;

  public isToDoListAvailableEmpty = false;

  public isToDoListThisWeekEmpty = false;

  public isToDoListNextWeekEmpty = false;

  public isToDoListAvailableLoading = true;

  public isToDoListThisWeekLoading = true;

  public isToDoListNextWeekLoading = true;

  private storeId;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
      private auth: CsaAuthService, 
      private toDoListService: ToDoListService, 
  ) {
    this.auth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.storeId = this.auth.user.storeID;
      
      this.getToDoListAvailable();
      this.getToDoListThisWeek();
      this.getToDoListNextWeek();
    });
  }

  getToDoListAvailable() {
    this.toDoListAvailable = null;
    this.isToDoListAvailableEmpty = false;

    this.isToDoListAvailableLoading = true;

    this.toDoListService
      .getTodoList(
        'available',
        this.storeId
      )
      .pipe(take(1)).subscribe(
        (toDoListData) => {
          this.toDoListAvailable = toDoListData;
          this.isToDoListAvailableLoading = false;

          // check if the todo list is empty (no items in any of the response arrays)
          if (toDoListData.actions.mrr_actions.length + toDoListData.actions.standard.length + toDoListData.surveys.adhoc.length + toDoListData.surveys.mrr.length + toDoListData.surveys.recurring.length + toDoListData.surveys.standard.length == 0) {
            this.isToDoListAvailableEmpty = true;
          }
        },
        (error) => {
          this.isToDoListAvailableLoading = false;
          throw new Error('error loading to do list');
        }
      );
  }

  getToDoListThisWeek() {
    this.toDoListThisWeek = null;
    this.isToDoListThisWeekEmpty = false;

    this.isToDoListThisWeekLoading = true;

    this.toDoListService
      .getTodoList(
        'this_week',
        this.storeId
      )
      .pipe(take(1)).subscribe(
        (toDoListData) => {
          this.toDoListThisWeek = toDoListData;
          this.isToDoListThisWeekLoading = false;

          // check if the todo list is empty (no items in any of the response arrays)
          if (toDoListData.actions.mrr_actions.length + toDoListData.actions.standard.length + toDoListData.surveys.adhoc.length + toDoListData.surveys.mrr.length + toDoListData.surveys.recurring.length + toDoListData.surveys.standard.length == 0) {
            this.isToDoListThisWeekEmpty = true;
          }
        },
        (error) => {
          this.isToDoListThisWeekLoading = false;
          throw new Error('error loading to do list');
        }
      );
  }

  getToDoListNextWeek() {
    this.toDoListNextWeek = null;
    this.isToDoListNextWeekEmpty = false;

    this.isToDoListNextWeekLoading = true;

    this.toDoListService
      .getTodoList(
        'next_week',
        this.storeId
      )
      .pipe(take(1)).subscribe(
        (toDoListData) => {
          this.toDoListNextWeek = toDoListData;
          this.isToDoListNextWeekLoading = false;

          // check if the todo list is empty (no items in any of the response arrays)
          if (toDoListData.actions.mrr_actions.length + toDoListData.actions.standard.length + toDoListData.surveys.adhoc.length + toDoListData.surveys.mrr.length + toDoListData.surveys.recurring.length + toDoListData.surveys.standard.length == 0) {
            this.isToDoListNextWeekEmpty = true;
          }
        },
        (error) => {
          this.isToDoListNextWeekLoading = false;
          throw new Error('error loading to do list');
        }
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
