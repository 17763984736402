<div class="page-container">

	<div class="store-survey">
		<!-- <div class="survey-page-stat">
			<h1 [ngClass]="{
								'light-green': surveyDetails['status'] == 'active',
								'light-blue' : surveyDetails['status'] == 'started'
							}">
				{{ surveyDetails['completion'] | percent }}
			</h1>
			<p>Complete</p>
		</div> -->


		<h1>{{surveyDetails['title'] | unescape}}</h1>


		<h2 class="left-align">Assessment Details:</h2>
		<div class="audit-tool-cards-container">
			<div class="p2 audit-tool-card list-screen-card">


				<div class="question-options-row no-bottom-margin">

					<div class="survey-detail-block">
						<h4>Store:</h4>
						<p>
							{{surveyDetails['storeID']}} - {{surveyDetails['storeName']}}
						</p>
					</div>


					<div class="survey-detail-block">
						<h4>Draft Created:</h4>
						<p>{{ surveyDetails['publishDate']| date :'dd MMM y' }} </p>
					</div>

					<div class="survey-detail-block" *ngIf="surveyDetails['rating']">
						<h4>Final Rating:</h4>
						<p> {{surveyDetails['rating']}} </p>
					</div>

					<div class="survey-detail-block">
						<h4>Status:</h4>
						<p class="light-green medium-text">{{surveyDetails['status'] | titlecase}}</p>
					</div>

				</div>

			</div>
		</div>
		<!-- draft report button -->

		<div class="question-options-row" *ngIf="surveyDetails['storeManager'] && surveyDetails['LeadAuditor']">
			<div class="filter-chips">
				<div class="chipDrop">
					<mat-chip-list aria-label="Filters">
						<mat-chip color="primary" selected (click)="editHeader()">
							<span> Draft Report</span>
						</mat-chip>
					</mat-chip-list>
				</div>
			</div>
		</div>

		<!-- final report part -->
		<div *ngIf="surveyDetails['finalReportDate'] && surveyDetails['summaryFindings']">
			<div class="question-options-row">
				<div class="filter-chips">
					<div class="chipDrop">
						<mat-chip-list aria-label="Filters">
							<mat-chip color="primary" selected (click)="finalRate()">
								<span> Final Report</span>
							</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</div>
		</div>

		<!-- sign off comments  part -->
		<div *ngIf="signedOffByAnyRole">
			<div class="question-options-row">
				<div class="filter-chips">
					<div class="chipDrop">
						<mat-chip-list aria-label="Filters">
							<mat-chip color="primary" selected (click)="OpenSignOffComments()">
								<span> Sign off Details</span>
							</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</div>
		</div>


		<!-- visit note part only available for closed assessments and only can edit by above store users-->
		<div *ngIf="surveyDetails['status'] === 'closed' ">
			<div *ngIf="(viewNotesAvailable)">
				<div class="question-options-row">
					<div class="filter-chips">
						<div class="chipDrop">
							<mat-chip-list>
								<mat-chip color="primary" selected (click)="OpenVisitNotes()">
									<span> Visit Notes</span>
								</mat-chip>
							</mat-chip-list>
						</div>
					</div>
				</div>
			</div>
		</div>



		<form #surveyForm="ngForm" (submit)="submitForm(surveyForm.value)">
			<a #saveButton (click)="saveForm('All')" class="csa-button-solid csa-floating-button" *ngIf="canEdit">
				<span *ngIf="saving == true" class="padded-icon">
					<fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
				</span>
				<span *ngIf="saved == true && saving == false" class="padded-icon">
					<fa-icon [icon]="faCheck"></fa-icon>
				</span>
				<span *ngIf="saved == false && saving == false" class="padded-icon">
					<fa-icon [icon]="faSave"></fa-icon>
				</span>
				<span *ngIf="saved == false && saving == false">&nbsp;&nbsp;</span>Save<span
					*ngIf="saved == true && saving == false">d</span>
			</a>
			<div class="survey-container">
				<h2 class="left-align">Material Risk Review Findings:</h2>
				<div class="question-options-row no-top-margin no-bottom-margin">
					<h3 class="light-green medium-text expand-collapse" (click)="allExpandState = !allExpandState">
						<span *ngIf="allExpandState == false">
							&nbsp;&nbsp;Show All
							<fa-icon [icon]="faAngleDown"></fa-icon>
						</span>
						<span *ngIf="allExpandState == true">
							&nbsp;&nbsp;Hide All
							<fa-icon [icon]="faAngleUp"></fa-icon>
						</span>
						&nbsp;&nbsp;
					</h3>
				</div>

				<mat-accordion class="questions-accordion" multi>
					<div *ngFor="let section of surveyDetails['sectionHeading']; let sectionIndex = index;">
						<mat-expansion-panel
							*ngIf="(surveyDetails['questions'] | groupQuestions:{sectionHeading: section}).length > 0"
							[expanded]="allExpandState" #panel hideToggle (opened)="setOpened(sectionIndex)"
							(closed)="setClosed(sectionIndex)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<span class="neg-5-margin hide-on-mobile">
										<svg height="3" width="200">
											<line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
										</svg>
									</span>
									&nbsp;&nbsp;<span>{{section}}</span>&nbsp;&nbsp;<fa-icon
										*ngIf="openItems.indexOf(sectionIndex) === -1" [icon]="faAngleDown"></fa-icon>
									<fa-icon *ngIf="openItems.indexOf(sectionIndex) > -1" [icon]="faAngleUp"></fa-icon>
									&nbsp;&nbsp;
									<span class="neg-5-margin hide-on-mobile">
										<svg height="3" width="200">
											<line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
										</svg>
									</span>
								</mat-panel-title>
							</mat-expansion-panel-header>

							<!-- <span
							*ngIf="(surveyDetails['questions'] | groupQuestions:{sectionHeading: section}).length == 0">
							No findings under this section
						</span> -->

							<div>
								<div
									*ngFor="let question of (surveyDetails['questions'] | groupQuestions:{sectionHeading: section}); let sectionQuestionIndex = index;">
									<div class="audit-tool-card question-card">
										<div class="p2">
											<div class="question-count">
												<span class="large-count">Finding {{sectionQuestionIndex+1}}</span> of
												{{(surveyDetails['questions'] | groupQuestions:{sectionHeading:
												section}).length}}
											</div>

											<h3 class="actions-heading no-top-margin">
												Title
											</h3>
											<div class="action-line">
												{{question['title']}}
											</div>


											<h3 class="actions-heading no-top-margin" *ngIf="question['riskLevel']">
												Risk Level
											</h3>
											<div class="action-line" *ngIf="question['riskLevel']">
												{{question['riskLevel']}}
											</div>


											<h3 class="actions-heading no-top-margin" *ngIf="question['reference']">
												Reference
											</h3>
											<div class="action-line" *ngIf="question['reference']">
												{{question['reference']}}
											</div>

											<div class="centered-fields">
												<div class="multiple-field" [style.--numShown]="2">
													<h3 class="actions-heading no-top-margin">
														Type
													</h3>
													<div class="action-line">
														{{question['MRRtype'] | titlecase}}
													</div>
												</div>
												<div class="multiple-field" [style.--numShown]="2">
													<h3 class="actions-heading no-top-margin">
														Available To
													</h3>
													<div class="action-line">
														{{availableToString(question['availableTo'])}}
													</div>
												</div>
											</div>

											<h3 class="actions-heading no-top-margin" *ngIf="question['rootCause']">
												Root Cause
											</h3>
											<div class="action-line" *ngIf="question['rootCause']">
												{{question['rootCause']}}
											</div>

											<h3 class="actions-heading no-top-margin">
												Detailed Findings
											</h3>
											<div class="action-line">
												{{question['description']}}
											</div>
											<!-- show static notes -->

											<div *ngIf="!canEdit">
												<h3 class="actions-heading no-top-margin"
													*ngIf="(question['reviewedFrom'] || question['reviewedTo'])">
													Period Reviewed</h3>
												<div class="action-line"
													*ngIf="(question['reviewedFrom'] || question['reviewedTo'] )">
													<span *ngIf="question['reviewedFrom']">From
														{{ question['reviewedFrom']| date :'dd MMM y' }} </span><span
														*ngIf="question['reviewedTo']">&nbsp; To
														{{ question['reviewedTo']| date :'dd MMM y' }} </span>
												</div>
												<h3 class="actions-heading no-top-margin" *ngIf="question['notes'] ">
													Notes
												</h3>
												<div class="action-line" *ngIf="question['notes'] ">
													{{question['notes'] | unescape}}
												</div>
											</div>
											<!-- add note -->

											<div *ngIf="canEdit">
												<h3 class="actions-heading  no-top-margin pb3"
													*ngIf="question['notes'] || question['reviewedFrom'] || question['reviewedTo'] || question['showNotes']">
													Review Period
												</h3>
												<div class="centered-fields"
													*ngIf="question['notes'] || question['reviewedFrom'] || question['reviewedTo'] || question['showNotes']">
													<div class="multiple-field" [style.--numShown]="2">
														<mat-form-field appearance="outline" color="primary">
															<mat-label class="">From</mat-label>
															<input (blur)="findInvalidControls()"
																[ngModel]="question['reviewedFrom']"
																(ngModelChange)="saveForm(question['question_id'])"
																name="{{question['question_id'] + 'reviewedFrom'}}"
																readonly="true" (keydown)="false" ngModel matInput
																[matDatepicker]="picker" (click)="picker.open()">
															<mat-datepicker-toggle matSuffix [for]="picker">
															</mat-datepicker-toggle>
															<mat-datepicker #picker touchUi="true"></mat-datepicker>
														</mat-form-field>
													</div>
													<div class="multiple-field" [style.--numShown]="2">
														<mat-form-field appearance="outline" color="primary">
															<mat-label class="">To</mat-label>
															<input (blur)="findInvalidControls()"
																[ngModel]="question['reviewedTo']"
																[min]="surveyForm.value[question['question_id'] + 'reviewedFrom']"
																(ngModelChange)="saveForm(question['question_id'])"
																name="{{question['question_id'] + 'reviewedTo'}}"
																readonly="true" (keydown)="false" ngModel matInput
																[matDatepicker]="topicker" (click)="topicker.open()">
															<mat-datepicker-toggle matSuffix [for]="topicker">
															</mat-datepicker-toggle>
															<mat-datepicker #topicker touchUi="true"></mat-datepicker>
															<mat-error>Please select a date after "From" Date
															</mat-error>
														</mat-form-field>
													</div>
												</div>
												<mat-form-field appearance="outline"
													*ngIf="question['notes'] || question['reviewedFrom'] || question['reviewedTo'] || question['showNotes']"
													color="primary">
													<mat-label class="">Notes</mat-label>
													<textarea (keyup)="saveNotes(question['question_id'])"
														spellcheck="true" cdkTextareaAutosize
														#autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput
														#noteSection (ngModelChange)="question['notes']=$event"
														[ngModel]="question['notes'] | unescape"
														class="custom-control-input"
														name="{{question['question_id'] + 'note'}}"></textarea>
													<!--<mat-hint align="end">{{noteSection['value']?.length || 0}}/500</mat-hint>-->
													<mat-error>Please Enter a Note</mat-error>
												</mat-form-field>
											</div>
											<!-- add note -->

											<!-- <h3 class="actions-heading no-top-margin"
											*ngIf="question['actions']?.length > 0">
											Actions
										</h3>
										<div class="action-line" *ngFor="let action of question['actions']">
											<div class="file-name">
												{{action['title']?.slice(0, 25) | unescape}}{{action['title']?.length >
												25 ? '...' : ''}}
											</div>
											<div *ngIf="!action['actionId']" class="right-align">
												{{getDate(action['dueDate'])}}
											</div>
											<div *ngIf="action['actionId']" class="right-align medium-text">
												<a class="light-green"
													[routerLink]="['/action-details', action['actionId']]">
													<span *ngIf="user['role'] == 'Store'">Edit</span>
													<span *ngIf="user['role'] != 'Store'">View</span>
												</a>
											</div>
										</div> -->
										</div>

										<!-- action part	 -->


										<div class="actions-section">

											<h3 class="actions-heading  no-top-margin"
												*ngIf="question['actions']?.length > 0 || question['autoSavedAction']?.length > 0">
												Actions</h3>
											<div class="action-line" *ngFor="let action of question['actions']">
												<div class="file-name">{{action['title'] | unescape | slice:0:25}}<span
														*ngIf="action['title']?.length > 25">...</span>
												</div>
												<div *ngIf="!action['actionId']" class="right-align">
													{{ action['dueDate']| date :'dd MMM y' }} </div>


												<div *ngIf="canEdit">
													<div *ngIf="action['actionId']" class="right-align">
														<a (click)="saveForm('All')"
															[routerLink]="['/mrr-action-details', action['actionId']]">
															<span> {{user['role'] === 'Store' || user['role'] ===
																'MultiStore' ? 'Edit' : 'View'}}</span>
														</a>
													</div>
												</div>
												<div *ngIf="!canEdit">
													<div *ngIf="action['actionId']" class="right-align">
														<a (click)="saveForm('All')"
															[routerLink]="['/mrr-action-details', action['actionId']]">
															<!-- <span> {{user['role'] == 'Store' || user['role'] == 'MultiStore' ?
																'Edit' : 'View'}}</span> -->
															<span> View</span>
														</a>
													</div>
												</div>

											</div>

											<div class="action-line"
												*ngFor="let autoSavedAction of question['autoSavedAction']">
												<div class="right-margin" *ngIf="autoSavedAction['title']">
													{{autoSavedAction['title']?.slice(0, 15) | unescape}}
													<span *ngIf="autoSavedAction['title']?.length > 15">...</span>
												</div>
												<div class="right-margin"
													*ngIf="!autoSavedAction['title'] && autoSavedAction['issueDescription']">
													{{autoSavedAction['issueDescription']?.slice(0, 15) | unescape}}
													<span
														*ngIf="autoSavedAction['issueDescription']?.length > 15">...</span>
												</div>
												<div class="right-margin"
													*ngIf="!autoSavedAction['title'] && !autoSavedAction['issueDescription']">
													{{ autoSavedAction['dueDate'] | date :'dd MMM y' }}
												</div>
												<div class="grey-text medium-text"><i>Unsaved</i></div>
												<div class="right-align">
													<a
														(click)="createAction(surveyDetails['title'], surveyDetails['id'], question['title'], question['question_id'], question['shortTitle'], autoSavedAction['autoSavedActionID'])">
														<fa-icon [icon]="faPencilAlt" [spin]="false"></fa-icon>
													</a>
													&nbsp;&nbsp;&nbsp;
													<a class="red-text"
														(click)="deleteAction(question['question_id'], autoSavedAction['autoSavedActionID'])">
														<fa-icon [icon]="faTrash" [spin]="false"></fa-icon>
													</a>
												</div>
											</div>

											<!-- evidence part -->
											<h3 class="actions-heading no-top-margin"
												*ngIf="question['photos']?.length > 0 || question['uploading']">
												Evidence
											</h3>
											<div class="action-line"
												*ngFor="let photo of question['photos']; let i = index">
												<div class="file-name">
													{{photo['photoName']?.slice(0, 25)}}{{photo['photoName']?.length >
													25 ?
													'...' : ''}}
												</div>
												<div class="right-align hide-on-mobile">
													{{photo['fileSize'] | filesize}}
												</div>
												<div class="right-align">
													<a (click)="
												this.user['divisionRoles'][this.user['role']]
													> this.user['divisionRoles']['MultiStore']
													? getAllPhotos(
															question['question_id'],
															i,
															photo['fileName']
														)
													: viewPhoto(
															photo['photoName'],
															question['question_id'],
															photo['fileName']
														)
												">
														View
													</a>
												</div>
											</div>

											<div class="action-line" *ngIf="question['uploading'] == true">
												<div class="light-blue">Evidence Uploading...</div>
												<div class="light-blue right-align">
													<span class="padded-icon">
														<fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
													</span>
												</div>
											</div>

											<h3 class="actions-heading  no-top-margin"
												*ngIf="question['pointInTimeReviewDate']">
												Reviewed On
											</h3>
											<div class="action-line" *ngIf="question['pointInTimeReviewDate']">
												<div class="file-name">
													Point In Time Review Date:
													{{ question['pointInTimeReviewDate'] | date :'dd MMM y' }}
												</div>
											</div>
											<!-- evidence part -->

										</div>

										<div class="p2">
											<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (getSurveyResponses(question['question_id']) === 'No' && !question['radioOptions'] )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
												Please Create an Action when
												{{getSurveyResponses(question['question_id'])}}
												is Selected
											</h5>

											<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (question['radioOptions'] && getSurveyResponses(question['question_id']) == question.radioOptions[0].answer && (question.radioOptions[0].enforceAction) == true )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
												Please Create an Action when
												{{getSurveyResponses(question['question_id'])}}
												is Selected
											</h5>

											<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (question['radioOptions'] && getSurveyResponses(question['question_id']) == question.radioOptions[1].answer && (question.radioOptions[1].enforceAction) == true )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
												Please Create an Action when
												{{getSurveyResponses(question['question_id'])}}
												is Selected
											</h5>

											<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (question['radioOptions'] && (question['radioOptions']?.length > 2) && getSurveyResponses(question['question_id']) == question.radioOptions[2].answer && (question.radioOptions[2].enforceAction) == true )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
												Please Create an Action when
												{{getSurveyResponses(question['question_id'])}}
												is Selected
											</h5>
										</div>

										<h5 class="med-grey"
											*ngIf="(question['autoSavedAction'] | keyvalue)?.length > 0">
											Please
											Complete the Above Action</h5>

										<!-- action part	 -->

										<div class="action-buttons" *ngIf="canEdit">
											<div class="csa-button-solid action-button note-action"
												(click)="makeNoteShow(question['question_id'])">
												<span class="action-icon">
													<fa-icon [icon]="faPencilAlt"></fa-icon>
												</span> Add Note
											</div>

											<div (click)="uploader.click()" [ngClass]="{
											'semi-disabled' : question['uploading'] == true,
											'disabled' : question['photos']?.length >= 10
										}" class="csa-button-solid action-button">
												<span class="action-icon">
													<fa-icon *ngIf="question['uploading'] == true"
														[icon]="faCircleNotch" [spin]="true"></fa-icon>
													<fa-icon *ngIf="question['uploading'] == false" [icon]="faCamera">
													</fa-icon>
												</span>
												Add Evidence
												<input hidden type="file" #uploader (click)="uploader.value = null"
													(change)="uploadPhoto($event, question['question_id'], surveyDetails['id'])" />
											</div>

											<div [ngClass]="{
																	'red-background': !question['actions'] && ((question['autoSavedAction'] | keyvalue)?.length == 0 && question['actions']?.length < 5),

																	'disabled' : question['actions']?.length >= 5 || (question['autoSavedAction'] | keyvalue)?.length > 0
																}" class="csa-button-solid action-button action-action"
												(click)="createAction(surveyDetails['title'], surveyDetails['id'], question['title'], question['question_id'], '', 'new')">
												<span class="action-icon">
													<fa-icon [icon]="faCalendarCheck"></fa-icon>
												</span> Add Actions
											</div>
										</div>

									</div>
								</div>
							</div>
						</mat-expansion-panel>
					</div>
				</mat-accordion>
			</div>

			<div class="submit-button-container center-align" *ngIf="canEdit">
				<div class="medium-text" *ngIf="outstandingActions >= 1"><span class="subtle-centered-text">You have
					</span>
					<span class="med-grey">{{outstandingActions}}</span> <span class="subtle-centered-text"> Finding
						which
						Require<span *ngIf="outstandingActions > 1">s</span> an Action</span>
				</div>
				<div class="medium-text" *ngIf="incompleteActions >= 1"><span class="subtle-centered-text">You have
					</span>
					<span class="med-grey">{{incompleteActions}}</span> <span class="subtle-centered-text"> Unsaved
						Action<span *ngIf="incompleteActions > 1">s</span>,
						please save or delete this to continue</span>
				</div>

				<div class="medium-text" *ngFor="let field of invalid"><span class="subtle-centered-text">Finding
					</span><span class="med-grey">{{field}}</span> <span class="subtle-centered-text"> Has an Invalid
					</span><span class="med-grey"> Reviewed To Date</span> </div>
				<button [disabled]="!surveyForm.valid || !actionsValid" class="csa-button-solid survey-submit "
					type="submit"><span>
						<fa-icon [icon]="faPaperPlane"></fa-icon>
					</span> Submit</button>


			</div>



		</form>

	</div>
</div>