<div class="page-container">

  <h1>{{surveyDetails['title'] | unescape}}</h1>

  <h2 class="left-align">Assessment Details:</h2>
  <div class="audit-tool-cards-container">
    <div class="p2 audit-tool-card list-screen-card">


   


      <div class="question-options-row no-bottom-margin">

        <div class="survey-detail-block">
          <h4>Store:</h4>
          <p>
            {{surveyDetails['storeID']}} - {{surveyDetails['storeName']}}
          </p>
        </div>

      

        <div class="survey-detail-block">
          <h4>Draft Created:</h4>
          <p>{{ surveyDetails['publishDate'] | date :'dd MMM y' }}</p>

        </div>

        <div class="survey-detail-block" *ngIf="surveyDetails['rating']">
          <h4>Final Rating:</h4>
          <p> {{surveyDetails['rating']}} </p>
        </div>

        <div class="survey-detail-block">
          <h4>Status:</h4>
          <p class="light-green medium-text"> {{surveyDetails['status'] | titlecase}}</p>
        </div>

      </div>


      <div class="survey-button question-options-row no-top-margin">
        <h4 class="orange-text" *ngIf="surveyDetails['status'] === 'open'">
          This Assessment has not yet been activated<br>
        </h4>

        <h4 class="orange-text" *ngIf="surveyDetails['status'] === 'active'">
          This Assessment has been activated<br>
        </h4>

        <h4 class="orange-text" *ngIf="surveyDetails['status'] === 'started'">
          This Assessment has been Started<br>
        </h4>

        <h4 class="light-green" *ngIf="surveyDetails['status'] === 'submitted'">
          This Assessment has been Submitted <br>
        </h4>

        <h4 class="orange-text" *ngIf="surveyDetails['status'] === 'closed'">
          This Assessment has been Closed <br>
        </h4>

        <h4 class="orange-text" *ngIf="surveyDetails['status'] === 'cancelled'">
          This Assessment has been Cancelled <br>
        </h4>
      </div>

      <div class="survey-button question-options-row no-top-margin">
        <a [routerLink]="['/store-landing/mrr-store-survey', surveyDetails['id']]" [ngClass]="{
              'disabled-link': surveyDetails['status'] === 'submitted'
            }"
          *ngIf="!(surveyDetails['status'] === 'open' || surveyDetails['status'] === 'active' || surveyDetails['status'] === 'started' || surveyDetails['status'] === 'submitted' || surveyDetails['status'] === 'closed') && canEdit == true">
          <button class="csa-button-solid large">
            <fa-icon class="chip-left-icon" [icon]="faPlayCircle"></fa-icon>
            <span>{{surveyDetails['surveyState']}}</span>
          </button>
        </a>
      </div>
    </div>
  </div>
  <!-- draft report button -->
  <div class="question-options-row" *ngIf="canEdit">
    <div class="filter-chips">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip color="primary" selected (click)="editHeader()">
            <fa-icon class="chip-left-icon" [icon]="faPencilAlt"></fa-icon>
            <span> Draft Report</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <!-- final report part -->

  <div *ngIf="surveyDetails['storeManager'] && canEdit">
    <div class="question-options-row">
      <div class="filter-chips">
        <div class="chipDrop">
          <mat-chip-list aria-label="Filters">
            <mat-chip color="primary" selected (click)="finalRate(true,finalreport)">
              <fa-icon class="chip-left-icon" [icon]="faPencilAlt"></fa-icon>
              <span> Final Report</span>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>


  <!-- sign off comments  part -->
  <div *ngIf="signedOffByAnyRole">
    <div class="question-options-row">
      <div class="filter-chips">
        <div class="chipDrop">
          <mat-chip-list aria-label="Filters">
            <mat-chip color="primary" selected (click)="OpenSignOffComments()">
              <span> Sign off Details</span>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>




  <div class="survey-container">
    <h2 class="left-align">Material Risk Review Findings:</h2>

    <div class="question-options-row" *ngIf="canEdit">
      <div class="filter-chips">
        <div class="chipDrop">
          <mat-chip-list aria-label="Filters">
            <mat-chip color="primary" selected (click)="createFinding()">
              <fa-icon class="chip-left-icon" [icon]="faPlus"></fa-icon>
              <span>Create New Finding</span>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>

    
    <div class="question-options-row no-bottom-margin">
      <h3 class="light-green medium-text expand-collapse" (click)="allExpandState = !allExpandState">
        <span *ngIf="allExpandState == false">
          &nbsp;&nbsp;Show All
          <fa-icon [icon]="faAngleDown"></fa-icon>
        </span>
        <span *ngIf="allExpandState == true">
          &nbsp;&nbsp;Collapse All
          <fa-icon [icon]="faAngleUp"></fa-icon>
        </span>
        &nbsp;&nbsp;
      </h3>
    </div>


    <mat-accordion class="questions-accordion" multi>
      <mat-expansion-panel [expanded]="allExpandState" #panel hideToggle
        *ngFor="let section of surveyDetails['sectionHeading']; let sectionIndex = index;"
        (opened)="setOpened(sectionIndex)" (closed)="setClosed(sectionIndex)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="neg-5-margin hide-on-mobile">
              <svg height="3" width="200">
                <line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
              </svg>
            </span>
            &nbsp;&nbsp;<span>{{section}}</span>&nbsp;&nbsp;<fa-icon *ngIf="openItems.indexOf(sectionIndex) === -1"
              [icon]="faAngleDown"></fa-icon>
            <fa-icon *ngIf="openItems.indexOf(sectionIndex) > -1" [icon]="faAngleUp"></fa-icon>&nbsp;&nbsp;
            <span class="neg-5-margin hide-on-mobile">
              <svg height="3" width="200">
                <line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
              </svg>
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span *ngIf="(surveyDetails['questions'] | groupQuestions:{sectionHeading: section}).length == 0">
          No findings under this section
        </span>
        <div
          *ngFor="let question of (surveyDetails['questions'] | groupQuestions:{sectionHeading: section}); let sectionQuestionIndex = index;">

          <div class="audit-tool-card question-card">
            <div class="p2">
              <div class="close-container">
                <span class="info-icon" color="primary" *ngIf="canEdit" (click)="updateFindings(true, question)">
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                </span>
              </div>

              <div class="question-count">
                <span class="large-count">
                  Finding {{sectionQuestionIndex+1}}
                </span> of
                {{(surveyDetails['questions'] | groupQuestions:{sectionHeading: section}).length}}
              </div>

              <h3 class="actions-heading no-top-margin">
                Title
              </h3>
              <div class="action-line">
                {{question['title']}}
              </div>

              <h3 class="actions-heading no-top-margin" *ngIf="question['riskLevel']">
                Risk Level
              </h3>
              <div class="action-line" *ngIf="question['riskLevel']">
                {{question['riskLevel']}}
              </div>


              <h3 class="actions-heading no-top-margin" *ngIf="question['reference']">
                Reference
              </h3>
              <div class="action-line" *ngIf="question['reference']">
                {{question['reference']}}
              </div>

              <div class="centered-fields">
                <div class="multiple-field" [style.--numShown]="2">
                  <h3 class="actions-heading no-top-margin">
                    Type
                  </h3>
                  <div class="action-line">
                    {{question['MRRtype'] | titlecase}}
                  </div>
                </div>
                <div class="multiple-field" [style.--numShown]="2">
                  <h3 class="actions-heading no-top-margin">
                    Available To
                  </h3>
                  <div class="action-line">
                    {{availableToString(question['availableTo'])}}
                  </div>
                </div>
              </div>

              <h3 class="actions-heading no-top-margin" *ngIf="question['rootCause']">
                Root Cause
              </h3>
              <div class="action-line" *ngIf="question['rootCause']">
                {{question['rootCause']}}
              </div>

              <h3 class="actions-heading no-top-margin">
                Detailed Findings
              </h3>
              <div class="action-line">
                {{question['description']}}
              </div>

              <h3 class="actions-heading no-top-margin" *ngIf="question['reviewedFrom'] && question['reviewedTo']">
                Period Reviewed
              </h3>
              <div class="action-line" *ngIf="question['reviewedFrom'] && question['reviewedTo']">
                <span>
                  From {{ question['reviewedFrom'] | date :'dd MMM y' }} To {{ question['reviewedTo'] | date :'dd MMM y' }}
                </span>
              </div>

              <h3 class="actions-heading no-top-margin" *ngIf="question['notes']">
                Notes
              </h3>
              <div class="action-line" *ngIf="question['notes']">
                {{question['notes'] | unescape}}
              </div>

              <h3 class="actions-heading no-top-margin" *ngIf="question['actions']?.length > 0">
                Actions
              </h3>
              <div class="action-line" *ngFor="let action of question['actions']">
                <div class="file-name">
                  {{action['title']?.slice(0, 25) | unescape}}{{action['title']?.length > 25 ? '...' : ''}}
                </div>
                <div *ngIf="!action['actionId']" class="right-align">
                  {{ action['dueDate'] | date :'dd MMM y'}}
                </div>

                <div *ngIf="!signoffDone">
                  <div *ngIf="action['actionId']" class="right-align">
                    <a [routerLink]="['/mrr-action-details', action['actionId']]">
                   
                      <span> Edit</span>
                    </a>
                  </div>
                </div>
                <div *ngIf="signoffDone">
                  <div *ngIf="action['actionId']" class="right-align">
                    <a [routerLink]="['/mrr-action-details', action['actionId']]">
                    
                      <span> View</span>
                    </a>
                  </div>
                </div>

              </div>

              <h3 class="actions-heading no-top-margin" *ngIf="question['photos']?.length > 0 || question['uploading']">
                Evidence
              </h3>
              <div class="action-line" *ngFor="let photo of question['photos']; let i = index">
                <div class="file-name">
                  {{photo['photoName']?.slice(0, 25)}}{{photo['photoName']?.length > 25 ? '...' : ''}}
                </div>
                <div class="right-align hide-on-mobile">
                  {{photo['fileSize'] | filesize}}
                </div>
                <div class="right-align">
                
                  <a (click)="
                  this.user['divisionRoles'][this.user['role']] 
                    > this.user['divisionRoles']['MultiStore'] 
                    ? getAllPhotos(
                      question['question_id'], 
                        i, 
                        photo['fileName']
                      )
                    :viewPhoto(photo['photoName'], question['question_id'], photo['fileName'])">
                    View
                  </a>
                </div>
              </div>

              <div class="action-line" *ngIf="question['uploading'] == true">
                <div class="light-blue">Evidence Uploading...</div>
                <div class="light-blue right-align">
                  <span class="padded-icon">
                    <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
                  </span>
                </div>
              </div>

              <h3 class="actions-heading  no-top-margin" *ngIf="question['pointInTimeReviewDate']">
                Reviewed On
              </h3>
              <div class="action-line" *ngIf="question['pointInTimeReviewDate']">
                <div class="file-name">
                  Point In Time Review Date:    {{ question['pointInTimeReviewDate'] | date :'dd MMM y' }}
                </div>
              </div>
            </div>

            <div class="action-buttons" *ngIf="canEdit">
              <div (click)="uploader.click()" [ngClass]="{
                'semi-disabled' : question['uploading'] == true,
                'disabled' : question['photos']?.length >= 10
              }" class="csa-button-solid action-button finding-action">
                <span class="action-icon">
                  <fa-icon *ngIf="question['uploading'] == true" [icon]="faCircleNotch" [spin]="true"></fa-icon>
                  <fa-icon *ngIf="question['uploading'] == false" [icon]="faCamera"></fa-icon>
                </span>
                Add Evidence
                <input hidden type="file" #uploader (click)="uploader.value = null"
                  (change)="uploadPhoto($event, question['question_id'], surveyDetails['id'])" />
              </div>

            </div>
          </div>

        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="submit-button-container center-align"
    *ngIf="user['divisionRoles'][user['role']] >= user['divisionRoles']['Admin']">
    <div *ngIf="surveyDetails['status'] === 'open'">
      <div class="medium-text" *ngIf="surveyDetails['questions'].length == 0">
        <span class="subtle-centered-text">
          You must add at least one finding in order to publish this Material Risk Review Assessment.
        </span>
      </div>

      <button [disabled]="surveyDetails['questions'].length == 0" class="csa-button-solid survey-submit"
        (click)="publishMrr()">
        <fa-icon class="chip-left-icon" [icon]="faPaperPlane"></fa-icon>
        <span *ngIf="surveyDetails['status'] === 'open'">
          Activate
        </span>
        <span *ngIf="surveyDetails['status'] === 'started'">
          Update
        </span>
      </button>
    </div>

    <div *ngIf="surveyDetails['status'] === 'active' || surveyDetails['status'] === 'started'">
      <div class="medium-text">
        <span class="subtle-centered-text">
          This assessment is currently active. Creating new findings or editing existing findings will update the
          existing response.
        </span>
      </div>
    </div>


  </div>
</div>