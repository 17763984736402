import { Injectable } from '@angular/core';
import { CsaAuthService } from '../auth/csa-auth.service';
 
@Injectable({
  providedIn: 'root',
})
/**
 * Service for managing feature/subfeature access permissions for the logged on user.
 */
export class FeatureAccessService {
  constructor(private authService: CsaAuthService) { }

  /**
   * Checks if the user has access to a specific featureKey and subfeature key combination.
   *
   * @param {string} featureKey - The key of the feature.
   * @param {string} subfeatureKey - The key of the subfeature.
   * @returns {boolean} - True if the user has access; otherwise, false.
   */
  hasSubfeatureAccess(featureKey: string, subfeatureKey: string): boolean {
    const features = this.authService.user?.features;
    return !!features?.find((value) => value === `${featureKey}.${subfeatureKey}`);
  }

  /**
   * Checks if the user has access to a composite feature i.e. FeatureKey.subfeature..
   *
   * @param {string} compositeKey - The composite key of the feature.
   * @returns {boolean} - True if the user has access; otherwise, false.
   */
  hasAccess(compositeKey: string): boolean {
    const keyParts = compositeKey.split('.');
    if (!keyParts && keyParts.length !== 2) {
      return false;
    }
    return this.hasSubfeatureAccess(keyParts[0], keyParts[1]);
  }

  /**
   * Checks if the user has access to any feature based on the given composite keys.
   * A composite key is defined as {{featureKey}}.{{subfeatureKey}}.
   *
   * @param {string[]} compositeKeys - An array of composite keys to check access for.
   * @returns {boolean} Returns true if the user has access to any passed in composite keys, otherwise false.
   */
  hasAccessToAnyFeature(compositeKeys: string[]): boolean {
    let hasAccess = false;

    // Check access for each composite key
    for (const compositeKey of compositeKeys) {
      if (this.hasAccess(compositeKey)) {
        hasAccess = true;
        break;
      }      
    }
    return hasAccess;
  }
 
  /**
   * Calculates the number of given composite keys for which the user has access. 
   * Each key is checked for access, and the total count of keys with access is returned.
   * A composite key is defined as {{featureKey}}.{{subfeatureKey}}.
   *
   * @param {string[]} compositeKeys - An array of composite keys to check access for.
   * @returns {number} The count of composite keys for which access is granted.
   */
  hasAccessToAnyFeatureCount(compositeKeys: string[] | string): number {
    let hasAccessCount = 0;

    // Convert single string key to array if necessary
    if (typeof compositeKeys === 'string') {
      compositeKeys = [compositeKeys];
    }

    // Check access for each composite key
    for (const compositeKey of compositeKeys) {
      if (this.hasAccess(compositeKey)) {
        hasAccessCount++;
      }      
    }
    return hasAccessCount;
  }
}
