<div class="close-container">
	<span class="info-icon med-grey" (click)="closeMe()">
		<fa-icon [icon]="faTimes"></fa-icon>
	</span>
</div>

<mat-dialog-content>
	<form #editResponseForm="ngForm" (submit)="saveNotes(editResponseForm.value)"
		(keydown.enter)="$event.preventDefault()">
		<div class="create-action-form">

			<h2>For Store - {{response['storeID']}} - {{response['storeName']}}</h2>


			<h1 class="create-action-headings left-align">Store Manager:</h1>
			<mat-form-field appearance="outline" color="primary">
				<mat-label class="" for="storeManager">The name of the Store Manager</mat-label>
				<input spellcheck="true" ngModel #storeManager maxLength="50" pattern="[a-zA-Z0-9 ]+" matInput
					class="custom-control-input" name="storeManager" required (ngModelChange)="saveNotes($event)" [(ngModel)]="response['storeManager']" >
				<mat-hint align="end">{{storeManager['value'].length || 0}}/50</mat-hint>
				<mat-error>Please enter the name of the Store Manager without special characters</mat-error>
			</mat-form-field>


			<h1 class="create-action-headings left-align">Lead Auditor:</h1>
			<mat-form-field appearance="outline" color="primary">
				<mat-label class="" for="LeadAuditor">The name of the Lead Auditor</mat-label>
				<input spellcheck="true" ngModel #LeadAuditor maxLength="50" pattern="[a-zA-Z0-9 ]+" matInput
					class="custom-control-input" name="LeadAuditor" required (ngModelChange)="saveNotes($event)" [(ngModel)]="response['LeadAuditor']">
				<mat-hint align="end">{{LeadAuditor['value'].length || 0}}/50</mat-hint>
				<mat-error>Please enter the name of the Lead Auditor without special characters</mat-error>
			</mat-form-field>



			<!-- <h1 class="create-action-headings left-align">Rating:</h1>
			<div class="left-align size-msg">
			</div>
			<mat-form-field appearance="outline" color="primary">
				<mat-label class="" for="Rating">Rating</mat-label>
				<input type="number" max="4" min="1" ngModel #Rating matInput class="custom-control-input" name="Rating"
					(ngModelChange)="saveNotes($event)"  [(ngModel)]="response['Rating']">
				<mat-error>
					Please enter a number between 1 and 4
				</mat-error>
				<mat-hint style="color: var(--red-color);"
					*ngIf="(editResponseForm.value.Rating !== '') && (editResponseForm.value.Rating === 0 || editResponseForm.value.Rating < 1 || editResponseForm.value.Rating > 4)">
					Please enter a number between 1 and 4</mat-hint>
			</mat-form-field> -->

			<h1 class="create-action-headings left-align">Rating:</h1>
			<mat-form-field appearance="outline" color="primary">
				<mat-label class="" for="rating">Rating</mat-label>
				<mat-select #rating matInput [(ngModel)]="response['rating']" (ngModelChange)="saveNotes($event)"  class="custom-control-input"
				  name="rating" >
				  <mat-option *ngFor="let rating of ratings" [value]="rating">
					{{rating}}
				  </mat-option>
				</mat-select>
				<mat-error>Please select a rating</mat-error>
			  </mat-form-field>  


			<h1 class="create-action-headings left-align padding-top">Dates of Audit:</h1>
			<div class="left-align size-msg">
			</div>
			<div class="centered-fields">
				<div class="multiple-field" [style.--numShown]="2">
					<mat-form-field appearance="outline" color="primary">
						<mat-label>From</mat-label>
						<input  [ngModel]="response['fromDate']" name="fromDate" (keydown)="false"  ngModel matInput
							[matDatepicker]="fromDatepicker" (click)="fromDatepicker.open()" required
							(dateChange)="onDateChange($event)" (dateChange)="toDatepicker.open()" (ngModelChange)="saveNotes($event)" [(ngModel)]="response['fromDate']">
						<mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
						<mat-datepicker #fromDatepicker ></mat-datepicker>
						<mat-error>Please select a date</mat-error>
					</mat-form-field>
				</div>

				<div class="multiple-field" [style.--numShown]="2">
					<mat-form-field appearance="outline" color="primary">
						<mat-label>To</mat-label>
						<input   [ngModel]="response['toDate']" name="toDate" (keydown)="false" [min]="editResponseForm.value.fromDate"
							[max]="maxDate" ngModel matInput [matDatepicker]="toDatepicker" (click)="toDatepicker.open()" required
							(ngModelChange)="saveNotes($event)"  [(ngModel)]="response['toDate']">
						<mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
						<mat-datepicker #toDatepicker></mat-datepicker>
						<mat-error>Please select a date after the selected "From Date"</mat-error>
					</mat-form-field>
				</div>
			</div>


			<h1 class="create-action-headings left-align padding-top">Due Date:</h1>
			<div class="left-align size-msg">

			</div>
			<mat-form-field appearance="outline" color="primary">
				<mat-label>Completion Date</mat-label>
				<input [ngModel]="response['dueDate']" name="dueDate" (keydown)="false"  [min]="today"
					 ngModel matInput [matDatepicker]="completionpicker" (click)="completionpicker.open()" 
					   required  [(ngModel)]="response['dueDate']">
				<mat-datepicker-toggle matSuffix [for]="completionpicker"></mat-datepicker-toggle>
				<mat-datepicker #completionpicker></mat-datepicker>
				<mat-error>
					Please select a due date 
				</mat-error>
			</mat-form-field>
			
		</div>
		<!-- extra filed for file upload	 -->

			<h1 class="create-action-headings left-align padding-top">
                Draft Report
              </h1>
              <div class="action-line" *ngFor="let photo of photos; let i = index">
                <div class="file-name">{{photo['photoName']?.slice(0, 25)}}<span
                    *ngIf="photo['photoName']?.length > 25">...</span></div>
                <div class="right-align hide-on-mobile">{{photo['fileSize'] | filesize}}</div>
                <div class="right-align">
                  <a (click)="
                   viewfinalDoc(
                          photo['photoName'], 
                         'draft', 
                          photo['fileName'], 
                          true
                        )
                  ">
                    View
                  </a>
                </div>
              </div>

           
              <div class="action-line" *ngIf="uploading == true">
                <div class="light-blue">Uploading...</div>
                <div class="light-blue right-align"><span class="padded-icon">
                    <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
                  </span></div>
              </div>

             

			
              <button class="csa-button-solid survey-submit" (click)="uploader.click()" [ngClass]="{
                'semi-disabled' : uploading == true,
                'disabled' : photos?.length  >= 10
              }" >
                <span class="action-icon">
                  <fa-icon *ngIf="uploading == true" [icon]="faCircleNotch" [spin]="true"></fa-icon>
                  <fa-icon *ngIf="uploading == false" [icon]="faPaperclip"></fa-icon>
                </span>
                Upload Draft Report
                <input hidden type="file" #uploader (click)="uploader.value = null"
                (change)="uploadDoc($event,'draft', response['id'])" />
              </button>
           

		
		<!-- extra filed for file upload	 -->

		

		<div class="submit-button-container">
			<div class="survey-link-container">
				<div *ngIf="!editResponseForm.valid || !editResponseForm.dirty && !uploadDocument" class="subtle-centered-text">
					<span *ngIf="!editResponseForm.dirty">
						The form has not been changed.
					</span>
					<span *ngIf="!editResponseForm.valid">
						Please complete all fields to save.
					</span>
				</div>
				
				<button [disabled]="!editResponseForm.valid || !editResponseForm.dirty && !uploadDocument" class="csa-button-solid survey-submit"
					type="submit" (click)="sendSurvey(editResponseForm, editResponseForm.value)">
					<span>
						<fa-icon [icon]="faPaperPlane"></fa-icon>
						Save Changes
					</span>
				</button>
			</div>
		</div>
	</form>
</mat-dialog-content>
