import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  tap, finalize, first, map, take, mergeMap, switchMap 
} from 'rxjs/operators';
import { CsaAuthService } from '../auth/csa-auth.service';
import { LoaderService } from '../common-services/loader.service';
import { DomainService } from '../domain/domain.service';

@Injectable()
export class CsaAuthInterceptor implements HttpInterceptor {
  constructor(private auth: CsaAuthService, public loaderService: LoaderService, private domainService: DomainService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const appDomain = this.domainService.getDomain();

    return this.auth.getAndUpdateToken$().pipe(take(1), switchMap((token) => {
      // if the user isn't signed in, attempt to make the request unauthenticated
      if (!this.auth.authToken) {
        return next.handle(request);
      }

      let requestWithAuthHeaders = request.clone({ headers: request.headers.set('Authorization', this.auth.authToken.token).set('Domain', appDomain) });

      const user = this.auth.user;

      if (user) {
        if (user['support'] || user['role'] == 'MultiStore' || user['super']) {
          if (user['role']) {
            // Pass the users role to the request header. Force the users role to be 'Support' when the Force-Support-Role request header is
            // found to be true and the user is a support user. This support role, for example, is required for Dynamic Role APIs.
            const userRole = request.headers.get('Force-Support-Role') == 'true' && user['support'] ? 'Support' : user['role'];
            requestWithAuthHeaders = requestWithAuthHeaders.clone({ headers: requestWithAuthHeaders.headers.set('role', userRole) });
          }
          if (user['division']) {
            requestWithAuthHeaders = requestWithAuthHeaders.clone({ headers: requestWithAuthHeaders.headers.set('division', user['division']) });
          }
          if (user['state']) {
            requestWithAuthHeaders = requestWithAuthHeaders.clone({ headers: requestWithAuthHeaders.headers.set('state', user['state']) });
          }
          if (user['zone']) {
            requestWithAuthHeaders = requestWithAuthHeaders.clone({ headers: requestWithAuthHeaders.headers.set('zone', user['zone']) });
          }
          if (user['groupNo']) {
            requestWithAuthHeaders = requestWithAuthHeaders.clone({ headers: requestWithAuthHeaders.headers.set('groupNo', user['groupNo']) });
          }
          if (user['storeID']) {
            requestWithAuthHeaders = requestWithAuthHeaders.clone({ headers: requestWithAuthHeaders.headers.set('storeID', user['storeID']) });
          }
          if (user['storeName']) {
            requestWithAuthHeaders = requestWithAuthHeaders.clone({ headers: requestWithAuthHeaders.headers.set('storeName', user['storeName']) });
          }
          if (user['region']) {
            requestWithAuthHeaders = requestWithAuthHeaders.clone({ headers: requestWithAuthHeaders.headers.set('region', user['region']) });
          }
          if (user['area']) {
            requestWithAuthHeaders = requestWithAuthHeaders.clone({ headers: requestWithAuthHeaders.headers.set('area', user['area']) });
          }
        }
      }

      return next.handle(requestWithAuthHeaders);
    }));
  }
}
