<div class="page-container">
  <h1>Material Risk Review Assessments</h1>
  <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of surveyGroups">
          <div class="survey-line survey-heading-line no-ripple">
            <div class="survey-stat template-stat">
              <h4>Assessment</h4>
            </div>
            <div
              *ngIf="user['role'] === 'Admin' || user['role'] === 'StateManager' || user['role']==='GroupManager' || user['role']=== 'OperationManager' || user['role']==='Super' || user['role']==='NationalView'"
              class="survey-stat template-stat">
              <h4>Sections</h4>
            </div>
            <div
            *ngIf="user['role'] !== 'Admin' && user['role'] !== 'StateManager' && user['role']!=='GroupManager' && user['role']!== 'OperationManager' && user['role']!=='Super' && user['role']!=='NationalView'"
              class="survey-stat template-stat">
              <h4>Rating</h4>
            </div>
            <!--  <div *ngIf="surveyGroup != 'Upcoming' && user['role'] == 'Admin' " class="survey-stat template-stat hide-on-mobile">
              <h4>Active Responses</h4>
            </div> -->

            <div
              *ngIf="(surveyGroup !== 'Upcoming') && (user['role'] === 'Admin' || user['role'] === 'StateManager' || user['role']==='GroupManager' || user['role']=== 'OperationManager' || user['role']==='Super' || user['role']==='NationalView') "
              class="survey-stat template-stat hide-on-mobile">
              <h4>Responses</h4>
            </div>

            <div *ngIf="surveyGroup === 'Available' && user['role'] === 'Admin'" class="survey-stat template-stat">
              <h4>Create Draft</h4>
            </div>
            <div *ngIf="surveyGroup === 'Upcoming'" class="survey-stat template-stat">
              <h4>Publish Date</h4>
            </div>
            <div
              *ngIf="(surveyGroup === 'Closed') && (user['role'] === 'Admin' || user['role'] === 'StateManager' || user['role']==='GroupManager' || user['role']=== 'OperationManager' || user['role'] ==='Super' || user['role']==='NationalView')"
              class="survey-stat template-stat">
              <h4>End Date</h4>
            </div>
            <div
              *ngIf="user['role'] !== 'Admin' && user['role'] !== 'StateManager' && user['role']!=='GroupManager' && user['role']!== 'OperationManager' && user['role']!=='Super' && user['role']!=='NationalView'"
              class="survey-stat template-stat">
              <h4> Status </h4>
            </div>

            <div
            *ngIf="(surveyGroup !== 'Closed') &&  user['role'] !== 'Admin' && user['role'] !== 'StateManager' && user['role']!=='GroupManager' && user['role']!== 'OperationManager' && user['role']!=='Super' && user['role']!=='NationalView'"
            class="survey-stat template-stat">
            <h4> Create New </h4>
          </div>

          </div>

          <div *ngIf="!isLoading">
            <div *ngFor="let survey of surveysToShow">
              <div class="survey-line">
                <div class="survey-details-link">
                  <h3 class="grey-text center-vertical"
                    *ngIf="user['role'] === 'Admin' || user['role'] === 'StateManager' || user['role']==='GroupManager' || user['role']=== 'OperationManager' || user['role']==='Super' || user['role']==='NationalView'"
                    class="survey-details-link center-vertical" [routerLink]="['/mrr-survey-details', survey['id']]">
                    <!-- <span *ngIf="survey['filterName']" class="infoTag">{{survey['filterName'] | uppercase}}</span>
                    &nbsp;{{survey['title'].slice(0, 50) | unescape}} -->
                    {{survey['title'].slice(0, 50) | unescape}}
                    <span *ngIf="survey['title']?.length > 50">...</span>
                  </h3>
                  <h3 class="grey-text center-vertical"
                    *ngIf="user['role'] !== 'Admin' && user['role'] !== 'StateManager' && user['role']!=='GroupManager' && user['role']!== 'OperationManager' && user['role']!=='Super' && user['role']!=='NationalView'"
                    class="survey-details-link center-vertical" [routerLink]="['/store-landing/mrr-store-survey', survey['id']]">
                    {{survey['title'].slice(0, 50) | unescape}}
                    <span *ngIf="survey['title']?.length > 50">...</span>
                  </h3>

                </div>

                <div
                  *ngIf="user['role'] === 'Admin' || user['role'] === 'StateManager' || user['role']==='GroupManager' || user['role']=== 'OperationManager' || user['role']==='Super' || user['role']==='NationalView'"
                  class="survey-details-link center-vertical" [routerLink]="['/mrr-survey-details', survey['id']]">
                  <h1>{{survey['sectionHeading']?.length || '0' }}</h1>
                </div>

                <div
                *ngIf="user['role'] !== 'Admin' && user['role'] !== 'StateManager' && user['role']!=='GroupManager' && user['role']!== 'OperationManager' && user['role']!=='Super' && user['role']!=='NationalView'"
                  class="survey-details-link center-vertical" [routerLink]="['/store-landing/mrr-store-survey', survey['id']]">
                  <h3>{{survey['rating']}}</h3>
                </div>

                <!--  <div *ngIf="surveyGroup != 'Upcoming' && user['role'] == 'Admin'" class="survey-details-link hide-on-mobile center-vertical"
                  [routerLink]="['/mrr-survey-details', survey['id']]">
                  <h1>{{survey['activeResponseCount']}}</h1>
                </div> -->

                <div
                  *ngIf="(surveyGroup !== 'Upcoming') && (user['role'] === 'Admin' || user['role'] === 'StateManager' || user['role']==='GroupManager' || user['role']=== 'OperationManager' || user['role']==='Super' || user['role']==='NationalView') "
                  class="survey-details-link hide-on-mobile center-vertical"
                  [routerLink]="['/mrr-survey-details', survey['id']]">
                  <h1>{{survey['activeResponseCount']}}</h1>
                </div>

                <div *ngIf="surveyGroup === 'Upcoming' " class="survey-details-link center-vertical"
                  [routerLink]="['/mrr-survey-details', survey['id']]">
                  <h3>  {{ survey.startDate | date :'dd MMM y'  }} </h3>
                </div>



                <div
                  *ngIf="(surveyGroup === 'Closed') && (user['role'] === 'Admin' || user['role'] === 'StateManager' || user['role']==='GroupManager' || user['role']=== 'OperationManager' || user['role']==='Super' || user['role']==='NationalView')"
                  class="survey-details-link center-vertical" [routerLink]="['/mrr-survey-details', survey['id']]">
                  <h3>  {{survey.endDate| date :'dd MMM y'  }} </h3>
                </div>

                <div *ngIf=" (surveyGroup !== 'Closed') && user['role'] !== 'Admin' && user['role'] !== 'StateManager' && user['role'] !== 'GroupManager' && user['role'] !== 'OperationManager' && user['role']!=='Super' && user['role']!=='NationalView'" class="survey-details-link center-vertical"
                [routerLink]="['/store-landing/mrr-store-survey', survey['id']]">
                <h3>{{survey['mrrStatus']}}</h3>
              </div>

                <div
                *ngIf="user['role'] !== 'Admin' && user['role'] !== 'StateManager' && user['role']!=='GroupManager' && user['role']!== 'OperationManager' && user['role']!=='Super' && user['role']!=='NationalView'"
                  class="survey-details-link center-vertical" [routerLink]="['/store-landing/mrr-store-survey', survey['id']]">
                  <div class="survey-details-link survey-button">
                    <div class="survey-button">
                      <a [ngClass]="{'disabled-link' :  survey['mrrStatusBtn'] === 'Closed'}"
                          [routerLink]="['/store-landing/mrr-store-survey', survey.id]">
                          <button class="csa-button-solid" [ngClass]="{'disabled-btn' :  survey['mrrStatusBtn'] === 'Closed'}">
                            <fa-icon class="chip-left-icon" *ngIf="!( survey['mrrStatusBtn'] === 'Closed')" [icon]="faPlayCircle"></fa-icon>
                            <span>{{survey['mrrStatusBtn']}}</span>
                          </button>
                      </a>
                  </div>
                  </div>
                </div>

                <div class="survey-details-link survey-button"
                  *ngIf="surveyGroup === 'Available' && survey['status'] === 'published' && user['role'] === 'Admin'">
                  <button class="csa-button-solid" (click)="createMRR(survey)">
                    <span>
                      <fa-icon [icon]="faPlus"></fa-icon> Create
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <h3 class="none-found-message" *ngIf="surveysToShow.length == 0">
              You currently have no {{surveyGroup}} Material Risk Review Assessments.
            </h3>
          </div>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>
        </mat-tab>
      </mat-tab-group>

      <mat-paginator #paginator [pageIndex]=pageIndex [pageSize]=pageSize [length]="totalCount"
        [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]=false (page)="getNext($event)">
      </mat-paginator>
    </div>
  </div>
</div>
