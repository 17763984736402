import { Injectable } from '@angular/core';
import { removeLastToken } from '../utils/remove-last-token/remove-last-token';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  routes: IBreadcrumb[] = [
    { key: 'developer-handbook', display: 'Developer Handbook' },
    { key: 'support-landing', display: 'Session User Access Setup' },
    { key: 'switch-division', display: 'Switch Division' },
    { key: 'multi-store-selection', display: 'Multi Store Access Setup' },
    { key: 'to-do-list', display: 'My To Do List' },
    { key: 'login', display: 'Sign In' },
    { 
      key: 'access-management', 
      display: 'Access Management',
      children:
      [
        {
          key: 'manage-features',
          url: '/access-management/features/manage',
          display: 'Manage Features',
          children: [
            {
              key: 'manage-feature-add',
              url: '/access-management/feature/add',
              display: 'Add Feature',
            },
            {
              key: 'manage-subfeature-add',
              url: '/access-management/subfeature/add',
              display: 'Add Subfeature',
            },
            {
              key: 'manage-feature-edit',
              url: '/access-management/feature/edit/:id',
              display: 'Edit Feature',
            },
            {
              key: 'manage-subfeature-edit',
              url: '/access-management/subfeature/edit/:id',
              display: 'Edit Subfeature',
            },
          ]
        },
        {
          key: 'manage-permissions',
          url: '/access-management/permissions/manage',
          display: 'Manage Permissions',
          children: [
            {
              key: 'manage-permission-add',
              url: '/access-management/permission/add',
              display: 'Add Permission',
            },
            {
              key: 'manage-subpermission-add',
              url: '/access-management/subpermission/add',
              display: 'Add Subpermission',
            },
            {
              key: 'manage-permission-edit',
              url: '/access-management/permission/edit/:id',
              display: 'Edit Permission',
            },
            {
              key: 'manage-subpermission-edit',
              url: '/access-management/subpermission/edit/:id',
              display: 'Edit Subpermission',
            },
          ]
        },
        {
          key: 'feature-mapping',
          url: '/access-management/feature-mapping/manage',
          display: 'Feature Mapping',
          children: []
        },
        {
          key: 'manage-roles',
          url: '/access-management/roles/manage',
          display: 'Manage Roles',
          children: [
            {
              key: 'manage-role-add',
              url: '/access-management/role/add',
              display: 'Add Role',
            },      
            {
              key: 'manage-role-edit',
              url: '/access-management/role/edit/:id',
              display: 'Edit Role',
            },            

          ]
        },
        {
          key: 'manage-users',
          url: '/access-management/users/manage',
          display: 'Manage Access',
          children: [
            {
              key: 'manage-user-add',
              url: '/access-management/user/add',
              display: 'Add User',
            },            
            {
              key: 'manage-user-edit',
              url: '/access-management/user/edit/:id',
              display: 'Edit Users',
            },            
          ]
        },

      ]
    },
    {
      key: 'dashboard',
      display: 'Dashboard',
      children:
      [
        {
          key: 'admin-more-surveys',
          display: 'Once Off Assessments',
          children: [
            { key: 'admin-once-off-survey-details', display: 'Details' }
          ] 
        },
        { key: 'above-store-landing', display: 'Old Dashboard' },
        { key: 'admin-landing', display: 'Old Dashboard' },
        { key: 'store-landing', display: 'Old Dashboard' },
        {
          key: 'above-store-once-off',
          display: 'Once Off Assessments',
          children:
          [
            { key: 'above-store-once-off-survey-details', display: 'Details' }
          ]
        },
        { key: 'store-dashboard', display: 'Store Management' },
        { key: 'error', display: 'Page Not Found' },
        { key: 'thankyou', display: 'Thank You' },
        { key: 'more-evidence', display: 'Evidence' },
        { key: 'thankyou-mrr', display: 'MRR Thank You' },
        {
          key: 'more-actions',
          display: 'Actions',
          children: [
            { key: 'action-details', display: 'Details' }
          ] 
        },
        {
          key: 'actions-drill-down',
          display: 'Actions',
          children: [
            { key: 'drill-down-action-details', display: 'Details' }
          ] 
        },
        {
          key: 'mrr-actions-drill-down',
          display: 'MRR Actions',
          children: [
            { key: 'drill-down-mrr-action-details', display: 'Details' }
          ] 
        },
        {
          key: 'mrr-more-actions',
          display: 'MRR Actions',
          children: [
            { key: 'mrr-action-details', display: 'Details' }
          ] 
        },
        {
          key: 'admin-more-templates',
          display: 'Templates',
          children: [
            { key: 'template-details', display: 'Details' }
          ] 
        },
        { key: 'admin-reports', display: 'Reports' },
        {
          key: 'more-onthefly-surveys',
          display: 'Adhoc Assessments',
          children: [
            { key: 'onthefly-survey-details', display: 'Details' }
          ] 
        },
        {
          key: 'more-recurring-surveys',
          display: 'Recurring Assessments',
          children: [
            { key: 'recurring-survey-details', display: 'Details' }
          ] 
        },
        {
          key: 'more-mrr-surveys',
          display: 'MRR Assessments',
          children: [
            { key: 'store-mrr-draft-details', display: 'Draft Details' },
            { key: 'above-store-mrr-survey-details', display: 'Details' },
            { key: 'store-mrr-survey', display: 'Details' },
            { key: 'mrr-survey-details', display: 'Details' },
            { key: 'store-mrr-details', display: 'Details' },
            { key: 'admin-mrr-details', display: 'Draft Details' }
          ] 
        },
        {
          key: 'store-more-surveys',
          display: 'Assessments',
          children: [
            {
              key: 'store-survey-details', display: 'Details' 
            },
            {
              key: 'store-surveys', display: 'Details' 
            }
          ] 
        },

      ]
    },
  ];

  constructor() { }

  public getBreadCrumbsByRouteUrl(url: string): IBreadcrumb[] {
    // if there is no route url, we can't get breadcrumb data for this role
    if (!url) {
      throw new Error('No url found for the current route.');
    }

    let finalBreadcrumbs = [];
    this.routes.forEach((route) => {
      const breadcrumbs = [];
      if (this.checkRouteUrlInChildren(route, url, breadcrumbs)) {
        finalBreadcrumbs = breadcrumbs;
      }
    });

    if (finalBreadcrumbs.length === 0) {
      throw new Error(`No breadcrumbs found for ${url}. Is it missing in the routes array in navigation service?`);
    }
    
    return finalBreadcrumbs;
  }

  private doesUrlMatchBreadcrumbRoute(breadcrumbUrlToCheck: string, urlToCheck: string): boolean {
    if (breadcrumbUrlToCheck?.endsWith(':id')) {
      const sanitizedBreadcrumbUrlToCheck = removeLastToken(breadcrumbUrlToCheck, '/');
      const sanitizedUrlToCheck = removeLastToken(urlToCheck, '/');
      return sanitizedBreadcrumbUrlToCheck === sanitizedUrlToCheck;
    }
    return breadcrumbUrlToCheck === urlToCheck;
  }

  private checkRouteUrlInChildren(routeToCheck: any, routeUrlToLookFor: string, breadcrumbs: any[]) {
    if (this.doesUrlMatchBreadcrumbRoute(routeToCheck.url, routeUrlToLookFor)) {
      breadcrumbs.push(routeToCheck);
      return true;
    } if (routeToCheck.children) {
      routeToCheck.children.forEach((childRoute) => {
        const childBreadcrumbs = [];
        if (this.checkRouteUrlInChildren(childRoute, routeUrlToLookFor, childBreadcrumbs)) {
          breadcrumbs.push(routeToCheck);
          childBreadcrumbs.forEach((childBreadCrumb) => {
            breadcrumbs.push(childBreadCrumb);
          });
          return true;
        }
      });
    }

    if (breadcrumbs.length === 0) {
      return false;
    }
    return true;
  }

  public getBreadCrumbsByRouteKey(routeKey: string): IBreadcrumb[] {
    // if there is no route key, we can't get breadcrumb data for this role
    if (!routeKey) {
      throw new Error('No breadcrumbKey found for the current route in route data.');
    }

    let finalBreadcrumbs = [];
    this.routes.forEach((route) => {
      const breadcrumbs = [];
      if (this.checkRouteKeyInChildren(route, routeKey, breadcrumbs)) {
        finalBreadcrumbs = breadcrumbs;
      }
    });

    if (finalBreadcrumbs.length === 0) {
      throw new Error(`No breadcrumbs found for ${routeKey}. Is it missing in the routes array in navigation service?`);
    }
    
    return finalBreadcrumbs;
  }

  private checkRouteKeyInChildren(routeToCheck: any, routeKeyToLookFor: string, breadcrumbs: any[]) {
    if (routeToCheck.key === routeKeyToLookFor) {
      breadcrumbs.push(routeToCheck);
      return true;
    } if (routeToCheck.children) {
      routeToCheck.children.forEach((childRoute) => {
        const childBreadcrumbs = [];
        if (this.checkRouteKeyInChildren(childRoute, routeKeyToLookFor, childBreadcrumbs)) {
          breadcrumbs.push(routeToCheck);
          childBreadcrumbs.forEach((childBreadCrumb) => {
            breadcrumbs.push(childBreadCrumb);
          });
          return true;
        }
      });
    }

    if (breadcrumbs.length === 0) {
      return false;
    }
    return true;
  }
}

export interface IBreadcrumb {
  key: string,
  url?: string;
  display: string,
  children?: IBreadcrumb[]
}
