import {
  Component,
  OnInit,
  Input, KeyValueDiffers, Inject, KeyValueDiffer,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel, FormControl } from '@angular/forms';
import {
  faCheck,
  faPaperPlane,
  faInfoCircle,
  faTimes,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom, take } from 'rxjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { AdhocSurveyService } from 'src/app/adhoc-surveys/adhoc-survey.service';
import { RecurringSurveyService } from 'src/app/recurring-survey/recurring-survey.service';
import { MrrSurveysService } from 'src/app/mrr-surveys/mrr-surveys.service';
import { User } from '../../models/user';
import { SortPipe } from '../../custom-pipes/sort.pipe';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { LandingPageService } from '../landing-page.service';

@Component({
  selector: 'app-support-landing',
  templateUrl: './support-landing.component.html',
  styleUrls: ['./support-landing.component.scss'],
  providers: [
    SortPipe
  ]
})
export class SupportLandingComponent implements OnInit {
  faCheck = faCheck;

  faInfoCircle = faInfoCircle;

  faPaperPlane = faPaperPlane;

  faPlus = faPlus;

  faTimes = faTimes;

  faTrash = faTrash;

  // availableAreas: string[] = [];
  // availableGroups: string[] = [];
  // availableRegions: string[] = [];
  // availableRoles: string[] = [];
  // availableStates: string[] = [];
  // availableZones: string[] = [];
  // availableStores: string[] = [];
  // objDiffer: object = {};
  // showArea: boolean = false;
  // showGroup: boolean = false;
  // showRegion: boolean = false;
  // showState: boolean = false;
  // showZone: boolean = false;
  // showStore: boolean = false;
  // user: object = {};
  // userData: object = {};

  availableLocations: object = {};

  availableRoles: string[] = [];

  locationsOrdered: string[] = [];

  objDiffer: object = {};

  showLocation: boolean = false;

  showLocations: object = {};

  user: User = {};

  userOverride: object = {};

  isLoading: object = {};

  availableDivisions = [];

  currentLevel: string = '';

  nextLevel: string = '';

  lastLevel: string = '';

  private differ: KeyValueDiffer<string, any>;

  formIsValid = false;

  constructor(
    private differs: KeyValueDiffers,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private sortPipe: SortPipe,
    private surveyService : SurveysService,
    private landingPageService : LandingPageService
  ) { }

  ngOnInit() {
    this.user = this.csaAuth.user;

    if (this.user['domain'] == 'Bigw') {
      this.userOverride['division'] = this.user['division'];
      this.getRoles();
    } else {
      this.userOverride['division'] = '';
      this.surveyService
        .getNextLevel(`?domain=${this.user['domain']}`)
        .subscribe((divisionList) => {
          this.availableDivisions = divisionList['division'];
        });
    }
  }

  createLevelQuery(queryNextLevel): string {
    let key = 'division';
    let query = '';
    while (key != queryNextLevel) {
      if (key == 'division') {
        query = `?division=${this.userOverride['division']}`;
      } else if (key == 'groupID') {
        query += `&${key}=${this.userOverride['groupNo']}`;
      } else {
        query += `&${key}=${this.userOverride[key]}`;
      }
      key = this.userOverride['orgStruct'][key];
    }
    return query;
  }

  async getRoles() {
    const orgStructure = await firstValueFrom(this.surveyService.getOrgStructure(this.userOverride['division']));

    this.userOverride['orgStruct'] = orgStructure;

    let key = 'division';
    while (this.userOverride['orgStruct'][key]) {
      const value = this.userOverride['orgStruct'][key];
      if (this.locationsOrdered.indexOf(value) === -1) {
        this.locationsOrdered.push(value);
      }
      key = value;
    }

    this.availableRoles = [];
    const data = await firstValueFrom(this.landingPageService.getRoles());

    this.userOverride['divisionRoles'] = data[this.userOverride['division']];
    for (const [key, value] of Object.entries(this.userOverride['divisionRoles'])) {
      if (value > 0 && key != 'Support') {
        this.availableRoles.push(key);
      }
    }
    this.availableRoles.sort((a, b) => this.userOverride['divisionRoles'][b] - this.userOverride['divisionRoles'][a]);

    this.initRole();
  }

  getFiltersForLevel(level: string, reset: boolean = true): void {
    this.currentLevel = level;
    this.nextLevel = this.userOverride['orgStruct'][level];

    if (reset) {
      this.resetFiltersBelowLevel();
    }
    if (
      this.showLocations[this.nextLevel]
      && this.userOverride[this.currentLevel == 'groupID' ? 'groupNo' : this.currentLevel]
    ) {
      this.isLoading[this.nextLevel] = true;
      firstValueFrom(this.surveyService.getNextLevel(this.createLevelQuery(this.nextLevel))).then((data) => {
        this.isLoading[this.nextLevel] = false;
        this.availableLocations[this.nextLevel] = this.sortPipe.transform(data[this.nextLevel]);
        if (this.nextLevel != this.lastLevel) {
          this.getFiltersForLevel(this.nextLevel, reset);
        }
      }).catch((error) => {
        this.isLoading[this.nextLevel] = false;
        this.dialog.open(MessagesComponent, {
          data: {
            heading: `Error Finding ${level}s`,
            message: `Please email ${this.user['supportEmail']} for support.`
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(error);
      });
    }
  }

  resetUser() {
    this.nextLevel = 'division';
    this.userOverride['role'] = '';
    this.resetShownLocations();
    this.resetFiltersBelowLevel();
  }

  initRole(): void {
    this.showLocation = true;
    this.showLocations = {};
    switch (this.userOverride['role']) {
      case 'StateManager':
        this.lastLevel = 'state';
        break;
      case 'OperationManager':
        this.lastLevel = 'zone';
        break;
      case 'GroupManager':
        this.lastLevel = 'groupID';
        break;
      case 'RegionManager':
        this.lastLevel = 'region';
        break;
      case 'AreaManager':
        this.lastLevel = 'area';
        break;
      case 'Store':
        this.lastLevel = 'storeID';
        break;
      case 'StoreLossManager':
        this.lastLevel = 'state';
        break;
      default:
        this.lastLevel = 'division';
        this.showLocation = false;
        break;
    }

    this.resetShownLocations();

    this.currentLevel = this.getCurrentLevel();

    if (this.showLocation) {
      this.getFiltersForLevel(this.currentLevel, false);
    }
    this.isValid();
  }

  resetShownLocations(): void {
    if (!this.userOverride['orgStruct']) {
      return;
    }

    let key = this.userOverride['orgStruct']['division'];
    let show = true;
    while (this.userOverride['orgStruct'][key]) {
      if (this.lastLevel == 'division') {
        this.showLocations[key] = false;
      } else {
        this.showLocations[key] = show;
      }
      if (key == this.lastLevel) {
        show = false;
      }
      key = this.userOverride['orgStruct'][key];
    }

    if (this.userOverride['role'] == 'Store') {
      this.showLocations['storeID'] = true;
    }
  }

  getCurrentLevel(): string {
    let key = 'division';
    while (this.userOverride['orgStruct'][key]) {
      const value = this.userOverride['orgStruct'][key];
      if (!this.availableLocations[value] || this.availableLocations[value]?.length == 0) {
        return key;
      }
      key = value;
    }
    return 'division';
  }

  numShown(): number {
    return Object.values(this.showLocations).filter((value) => value === true).length;
  }

  isValid(): void {
    switch (this.userOverride['role']) {
      case 'StateManager':
        this.formIsValid = !!this.userOverride['state'];
        break;
      case 'OperationManager':
        this.formIsValid = !!this.userOverride['zone'];
        break;
      case 'GroupManager':
        this.formIsValid = !!this.userOverride['groupNo'];
        break;
      case 'RegionManager':
        this.formIsValid = !!this.userOverride['region'];
        break;
      case 'AreaManager':
        this.formIsValid = !!this.userOverride['area'];
        break;
      case 'StoreLossManager':
        this.formIsValid = !!this.userOverride['state'];
        break;
      default:
        this.formIsValid = true;
        break;
    }
  }

  resetFiltersBelowLevel(): void {
    let key = this.nextLevel;

    if (!this.userOverride['orgStruct']) {
      return;
    }

    while (this.userOverride['orgStruct'][key]) {
      this.availableLocations[key] = [];
      if (key == 'groupID') this.userOverride['groupNo'] = '';
      else this.userOverride[key] = '';
      key = this.user['orgStruct'][key];
    }
    if (key == 'storeID') {
      this.availableLocations['storeID'] = [];
      this.userOverride['storeID'] = '';
    }
  }

  saveForm() {
    this.userOverride['support'] = true;
    if (this.userOverride['role'] == 'Super') {
      this.userOverride['role'] = 'Admin';
      this.userOverride['super'] = true;
    } else if (this.userOverride['role'] == 'NationalView') {
      this.userOverride['super'] = true;
    }

    // loop through the overrides for the selected access and apply them to the user 
    for (const userOverrideKey in this.userOverride) {
      const userOverrideValue = this.userOverride[userOverrideKey];
      this.csaAuth.setUserOverride(userOverrideKey as any, userOverrideValue);
    }

    this.router.navigate(['/']);
  }

  splitByCaps(value): string {
    if (
      this.userOverride['division'] == 'DC'
      && (value == 'Store' || value == 'MultiStore')
    ) {
      if (value == 'Store') {
        return 'DC';
      }
      if (value == 'MultiStore') {
        return 'Multi DC User';
      }
    }

    if (
      value == 'Super'
      || value == 'Admin'
      || value == 'NationalView'
      || value == 'MultiStore'
    ) {
      value += 'User';
    } else if (value == 'OperationManager') {
      value = 'ZoneOperationManager';
    }

    return value.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
  }
}
