<div class="page-container">

  <h1>{{surveyDetails['title'] | unescape}}</h1>

  <h2 class="left-align">Assessment Details:</h2>
  <div class="audit-tool-cards-container">

    <div class="p2 audit-tool-card list-screen-card">
      <div class="question-options-row no-bottom-margin">
        <div class="survey-detail-block">
          <h4>Status:</h4>
          <h1 [ngClass]="{
                    'light-green': surveyDetails['surveyState'] === 'Complete',
                    'light-blue' : surveyDetails['surveyState'] === 'Continue'
                  }">{{ surveyDetails['completion'] | percent}}</h1>
        </div>
        <div class="survey-detail-block">
          <h4>No. of Questions:</h4>
          <p>{{surveyDetails['questions']?.length}}</p>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['publishDate']">
          <h4>Date Published:</h4>
          <p> {{ surveyDetails['publishDate'] | date :'dd MMM y'  }} </p>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['dueDate']">
          <h4>Due Date:</h4>
          <p>  {{ surveyDetails['dueDate'] | date :'dd MMM y'  }} </p>
        </div>
      </div>

      <div class="survey-button question-options-row no-top-margin">
        <h4 class="light-green" *ngIf="surveyDetails['status'] === 'submitted' && canEdit == true">
          This Assessment has been Submitted <br>
        </h4>

        <h4 class="orange-text" *ngIf="surveyDetails['status'] === 'closed' && canEdit == true">
          This Assessment has been Closed <br>
        </h4>
        
        <h4 class="orange-text" *ngIf="surveyDetails['status'] === 'cancelled' && canEdit == true">
          This Assessment has been Cancelled <br>
        </h4>

        <div class="survey-detail-block" *ngIf="canEdit == false && surveyDetails['type'] === 'adhoc'">
          <h4>Available To: </h4>
          <p *ngFor="let role of surveyDetails['targetRoles']; let j=index">
            {{splitByCaps(role)}}<span
              *ngIf="surveyDetails['targetRoles']?.length - 1 > j">,</span> 
          </p>
        </div>
      </div>

      <div class="survey-button question-options-row no-top-margin">
        <a [routerLink]="['/store-landing/store-survey', surveyDetails['id']]" [ngClass]="{
              'disabled-link': surveyDetails['status'] === 'submitted'
            }"
          *ngIf="!(surveyDetails['status'] === 'submitted' || surveyDetails['status'] === 'closed') && canEdit == true">
          <button class="csa-button-solid large">
            <fa-icon class="chip-left-icon" [icon]="faPlayCircle"></fa-icon>
            <span>{{surveyDetails['surveyState']}}</span>
          </button>
        </a>
        <button class="csa-button-solid large" (click)="printSelect.open()">
          <fa-icon class="chip-left-icon" [icon]="faFileInvoice"></fa-icon>
          <span>Filter Response</span>
        </button>
        <mat-select #printSelect class="optionsDropDown print-select">
          <mat-option (click)="printResponse('All');">
            All Questions
          </mat-option>
          <mat-option (click)="printResponse('Answered');">
            Answered Questions
          </mat-option>
          <mat-option (click)="printResponse('Unanswered');">
            Unanswered Questions
          </mat-option>
          <mat-option (click)="printResponse('No');">
            Questions Answered 'No'
          </mat-option>
          <mat-option (click)="printResponse('Yes');">
            Questions Answered 'Yes'
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div class="survey-container">
    <h2 class="left-align">Assessment Questions:</h2>
    <div class="question-options-row no-bottom-margin">
      <h3 class="light-green medium-text expand-collapse" (click)="allExpandState = !allExpandState">
        <span *ngIf="allExpandState == false">
          &nbsp;&nbsp;Show All
          <fa-icon [icon]="faAngleDown"></fa-icon>
        </span>
        <span *ngIf="allExpandState == true">
          &nbsp;&nbsp;Collapse All
          <fa-icon [icon]="faAngleUp"></fa-icon>
        </span>
        &nbsp;&nbsp;
      </h3>
    </div>
    <mat-accordion class="questions-accordion" multi>
      <mat-expansion-panel [expanded]="allExpandState" #panel hideToggle
        *ngFor="let heading of surveyDetails['sectionHeading']; let i = index;" (opened)="setOpened(i)"
        (closed)="setClosed(i)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="neg-5-margin hide-on-mobile">
              <svg height="3" width="200">
                <line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
              </svg>
            </span>
            &nbsp;&nbsp;<span>{{heading}}</span>&nbsp;&nbsp;<fa-icon *ngIf="openItems.indexOf(i) === -1"
              [icon]="faAngleDown"></fa-icon>
            <fa-icon *ngIf="openItems.indexOf(i) > -1" [icon]="faAngleUp"></fa-icon>&nbsp;&nbsp;
            <span class="neg-5-margin hide-on-mobile">
              <svg height="3" width="200">
                <line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
              </svg>
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div
          *ngFor="let question of (surveyDetails['questions'] | groupQuestions:{sectionHeading: heading}); let j = index;">
          <div class="audit-tool-card question-card">
            <div class="p2">
              <div class="question-count">
                <span class="large-count">Question {{j+1}}</span> of
                {{(surveyDetails['questions'] | groupQuestions:{sectionHeading: heading}).length}}
              </div>
              <h4 class="question-title" [innerHTML]="question.title"></h4>
              <div *ngIf="question['type'] === 'boolean' && !question['radioOptions']" class="question-options-row">
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] === 'Yes', 'csa-button-outline' : question['response'] !== 'Yes'}"
                  class="csa-button-outline small no-effect">Yes</span>
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] === 'No', 'csa-button-outline' : question['response'] !== 'No'}"
                  class="csa-button-outline small no-effect">No</span>
              </div>
              <div *ngIf="question.type === 'boolean' && question['radioOptions']" class="question-options-row">
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] == question.radioOptions[0].answer, 'csa-button-outline' : question['response'] != question.radioOptions[0].answer}"
                  class="csa-button-outline small no-effect">{{question.radioOptions[0].answer}}</span>
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] == question.radioOptions[1].answer, 'csa-button-outline' : question['response'] != question.radioOptions[1].answer}"
                  class="csa-button-outline small no-effect">{{question.radioOptions[1].answer}}</span>
              </div>
              <div *ngIf="question['type'] === 'boolean-na' && !question['radioOptions']" class="question-options-row">
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] === 'Yes', 'csa-button-outline' : question['response'] !== 'Yes'}"
                  class="csa-button-outline small no-effect">Yes</span>
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] === 'No', 'csa-button-outline' : question['response'] !== 'No'}"
                  class="csa-button-outline small no-effect">No</span>
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] === 'NA', 'csa-button-outline' : question['response'] !== 'NA'}"
                  class="csa-button-outline small no-effect">N/A</span>
              </div>
              <div *ngIf="question.type === 'boolean-na' && question['radioOptions']" class="question-options-row">
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] == question.radioOptions[0].answer, 'csa-button-outline' : question['response'] != question.radioOptions[0].answer}"
                  class="csa-button-outline small no-effect">{{question.radioOptions[0].answer}}</span>
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] == question.radioOptions[1].answer, 'csa-button-outline' : question['response'] != question.radioOptions[1].answer}"
                  class="csa-button-outline small no-effect">{{question.radioOptions[1].answer}}</span>
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] == question.radioOptions[2].answer, 'csa-button-outline' : question['response'] != question.radioOptions[2].answer}"
                  class="csa-button-outline small no-effect">{{question.radioOptions[2].answer}}</span>
              </div>
              <div *ngIf="question['type'] === 'radio' " class="question-options-column">
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] == question.radioOptions[0].answer, 'csa-button-outline' : question['response'] != question.radioOptions[0].answer}"
                  class="csa-button-outline no-effect">{{question.radioOptions[0].answer}}</span>
                <span
                  [ngClass]="{'csa-button-solid' : question['response'] == question.radioOptions[1].answer, 'csa-button-outline' : question['response'] != question.radioOptions[1].answer}"
                  class="csa-button-outline no-effect">{{question.radioOptions[1].answer}}</span>
                <span *ngIf="question.radioOptions[2]"
                  [ngClass]="{'csa-button-solid' : question['response'] == question.radioOptions[2]?.answer, 'csa-button-outline' : question['response'] != question.radioOptions[2]?.answer}"
                  class="csa-button-outline no-effect">{{question.radioOptions[2]?.answer}}</span>
              </div>
              <div *ngIf="question['type'] === 'freeText' " class="question-options-column">
                <mat-form-field appearance="outline" color="primary">
                  <mat-label class="">Free Text</mat-label>
                  <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput
                    #userSection (ngModelChange)="question['response']=$event"
                    [ngModel]="question['response'] | unescape" class="custom-control-input"
                    name="{{question['id'] + 'response'}}" disabled></textarea>
                  <mat-error>Please Enter a Manager Name</mat-error>
                </mat-form-field>
              </div>
              <div class="actions-section no-bottom-margin">
                <h3 class="actions-heading no-top-margin" *ngIf="question['reviewedFrom'] || question['reviewedTo']">
                  Period Reviewed</h3>
                <div class="action-line" *ngIf="question['reviewedFrom'] || question['reviewedTo']">
                  <span *ngIf="question['reviewedFrom']">From {{ question['reviewedFrom']| date :'dd MMM y'  }} </span><span
                    *ngIf="question['reviewedTo']">&nbsp; To {{ question['reviewedTo']| date :'dd MMM y'  }} </span>
                </div>
                <h3 class="actions-heading no-top-margin" *ngIf="question['notes']">Notes</h3>
                <div class="action-line" *ngIf="question['notes']">
                  {{question['notes'] | unescape}}
                </div>
                <h3 class="actions-heading no-top-margin" *ngIf="question['actions']?.length > 0">Actions</h3>
                <div class="action-line" *ngFor="let action of question['actions']">
                  <div class="file-name">{{action['title']?.slice(0, 25) | unescape}}<span
                      *ngIf="action['title']?.length > 25">...</span></div>
                  <div *ngIf="!action['actionId']" class="right-align"> {{ action['dueDate'] | date :'dd MMM y'  }} </div>
                  <div *ngIf="action['actionId']" class="right-align"><a
                      [routerLink]="['/action-details', action['actionId']]">
                    <span *ngIf="user['role'] === 'Store'">Edit</span>
                    <span *ngIf="user['role'] !== 'Store'">View</span></a></div>
                </div>
                <h3 class="actions-heading no-top-margin" *ngIf="question['photos']?.length > 0">Evidence</h3>
                <div class="action-line" *ngFor="let photo of question['photos']; let i = index">
                  <div class="file-name">{{photo['photoName'].slice(0, 25)}}<span
                      *ngIf="photo['photoName']?.length > 25">...</span></div>
                  <div class="right-align">{{photo['fileSize'] | filesize}}</div>
                  <div class="right-align">
                    <a (click)="
                      this.user['divisionRoles'][this.user['role']] 
                        > this.user['divisionRoles']['MultiStore'] 
                        ? getAllPhotos(
                            question['id'], 
                            photo['fileName'],
                            i
                          )
                        : viewPhoto(
                            photo['photoName'], 
                            question['id'], 
                            photo['fileName']
                          )
                      ">
                      View
                    </a>
                  </div>
                </div>
                <h3 class="actions-heading  no-top-margin" *ngIf="question['pointInTimeReviewDate']">Reviewed On</h3>
                <div class="action-line" *ngIf="question['pointInTimeReviewDate']">
                  <div class="file-name">Point In Time Review Date: {{ question['pointInTimeReviewDate']| date :'dd MMM y'  }} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>


	<div class="print-survey" id="printSurvey" #printSurvey>
		<h3 style="text-align: center">{{surveyDetails['title'] | unescape}}</h3>
		<span style="text-align: center" *ngIf="surveyDetails['type'] === 'adhoc' && user['role'] !== 'Store'">
			{{surveyDetails['storeID']}} - {{surveyDetails['storeName']}}
		</span>
		<br>

		<div *ngFor="let heading of surveyDetails['sectionHeading']">
			<ng-template #questionResponses [ngTemplateOutlet]="questionResponses" let-responses="responses" let-questions="questions"
			[ngTemplateOutletContext]="{ 
				responses: (surveyDetails['responses'] | groupQuestions:{sectionHeading: heading}),
				questions: (surveyDetails['questions'] | groupQuestions:{sectionHeading: heading})
			}">
        <div
					*ngFor="let question of questions; let i = index;">
					<div *ngIf="
						printVersion == 'All Questions' || 
						(printVersion == 'Answered Questions' && responses[i].response != '') || 
						(printVersion == 'Unans=wered Questions' && responses[i].response == '') || 
						(printVersion == 'Quest=ions Answered \'No\'' && responses[i].response == 'No' && question.type != 'freeT=ext') ||
						(printVersion == 'Quest=ions Answered \'Yes\'' && responses[i].response == 'Yes' && question.type != 'freeT=ext')
					">
						<span class="actions-heading pdf-pagebreak-before">
							<u><b>{{heading}}</b> - Question {{i+1}} of {{questions.length}}</u>
						</span>
						<br>
						<div [innerHTML]="(question['text'] ? question['text'] : question['title'])"></div> 
						<br>		

						<table data-pdfmake="{&quot;layout&quot;:&quot;noBorders&quot;, &quot;widths&quot;:[253, 253]}">
							<tr>
								<td>
									<span class="actions-heading no-top-margin">
										<i>Response:</i>
									</span>

									<div *ngIf="question.type !== 'freeText'">
										<br>
										<div *ngIf="question.type === 'boolean' && !question['radioOptions']">
											<div>
												<input id="{{question['id'] + 'bool1'}}" type="checkbox" [checked]="responses[i].response === 'Yes'" />
												<label [ngClass]="{'active' : responses[i].response === 'Yes'}" for="{{question['id'] + 'bool1'}}">
													{{responses[i].response === 'Yes' ? '(x)' : '(&nbsp;&nbsp;)'}} Yes
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'bool2'}}" type="checkbox" />
												<label [ngClass]="{'active' : responses[i].response === 'No'}" for="{{question['id'] + 'bool2'}}">
													{{responses[i].response === 'No' ? '(x)' : '(&nbsp;&nbsp;)'}} No
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'boolean' && question['radioOptions']">
											<div>
												<input id="{{question['id'] + 'bool1'}}" type="checkbox" [checked]="responses[i].response == question.radioOptions[0].answer" />
												<label [ngClass]="{'active' : responses[i].response == question.radioOptions[0].answer}" for="{{question['id'] + 'bool1'}}">
													{{responses[i].response == question.radioOptions[0].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[0].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'bool2'}}" type="checkbox" [checked]="responses[i].response == question.radioOptions[1].answer" />
												<label [ngClass]="{'active' : responses[i].response == question.radioOptions[1].answer}" for="{{question['id'] + 'bool2'}}">
													{{responses[i].response == question.radioOptions[1].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[1].answer}}
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'boolean-na' && !question['radioOptions']">
											<div>
												<input id="{{question['id'] + 'boolna1'}}" type="checkbox" [checked]="responses[i].response === 'Yes'" />
												<label [ngClass]="{'active' : responses[i].response === 'Yes'}" for="{{question['id'] + 'boolna1'}}">
													{{responses[i].response === 'Yes' ? '(x)' : '(&nbsp;&nbsp;)'}} Yes
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'boolna2'}}" type="checkbox" [checked]="responses[i].response === 'No'" />
												<label [ngClass]="{'active' : responses[i].response === 'No'}" for="{{question['id'] + 'boolna2'}}">
													{{responses[i].response === 'No' ? '(x)' : '(&nbsp;&nbsp;)'}} No
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'bool3'}}" type="checkbox" [checked]="responses[i].response === 'NA'" />
												<label [ngClass]="{'active' : responses[i].response === 'NA'}" for="{{question['id'] + 'bool3'}}">
													{{responses[i].response === 'NA' ? '(x)' : '(&nbsp;&nbsp;)'}} N/A
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'boolean-na' && question['radioOptions']">
											<div>
												<input id="{{question['id'] + 'boolna1'}}" type="checkbox" [checked]="responses[i].response == question.radioOptions[0].answer" />
												<label [ngClass]="{'active' : responses[i].response == question.radioOptions[0].answer}" for="{{question['id'] + 'boolna1'}}">
													{{responses[i].response == question.radioOptions[0].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[0].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'boolna2'}}" type="checkbox" [checked]="responses[i].response == question.radioOptions[1].answer" />
												<label [ngClass]="{'active' : responses[i].response == question.radioOptions[1].answer}" for="{{question['id'] + 'boolna2'}}">
													{{responses[i].response == question.radioOptions[1].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[1].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'bool3'}}" type="checkbox" [checked]="responses[i].response == question.radioOptions[2].answer" />
												<label [ngClass]="{'active' : responses[i].response == question.radioOptions[2].answer}" for="{{question['id'] + 'bool3'}}">
													{{responses[i].response == question.radioOptions[2].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[2].answer}}
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'radio'">
											<div>
												<input id="{{question['id'] + 'radio1'}}" type="checkbox" [checked]="responses[i].response == question.radioOptions[0].answer" />
												<label [ngClass]="{'active' : responses[i].response == question.radioOptions[0].answer }" for="{{question['id'] + 'radio1'}}">
													{{responses[i].response == question.radioOptions[0].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[0].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'radio2'}}" type="checkbox" [checked]="responses[i].response == question.radioOptions[1].answer" />
												<label [ngClass]="{'active' : responses[i].response == question.radioOptions[1].answer }" for="{{question['id'] + 'radio2'}}">
													{{responses[i].response == question.radioOptions[1].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[1].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'radio3'}}" type="checkbox" [checked]="responses[i].response == question.radioOptions[2].answer" />
												<label [ngClass]="{'active' : responses[i].response == question.radioOptions[2].answer }" for="{{question['id'] + 'radio3'}}">
													{{responses[i].response == question.radioOptions[2].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[2].answer}}
												</label>
											</div>
										</div>
										<br>
									</div>
									<div *ngIf="question['type'] === 'freeText'">
										<br>
										<hr data-pdfmake="{&quot;width&quot;:253, &quot;thickness&quot;:0.5}">
										<hr data-pdfmake="{&quot;width&quot;:253, &quot;thickness&quot;:0.5}">
										<div *ngIf="responses[i].response">
											{{responses[i].response | unescape}}
											<br>
										</div>
										<br>
									</div>
								</td>
								<td>
									<div class="actions-heading no-top-margin">
										<i>Notes:</i>
									</div>
									<div *ngIf="question['notes']">
										<br>
										{{question['notes'] | unescape}}
									</div>
									<br>
									<hr data-pdfmake="{&quot;width&quot;:253, &quot;thickness&quot;:0.5}">
									<hr data-pdfmake="{&quot;width&quot;:253, &quot;thickness&quot;:0.5}">
								</td>
							</tr>
							<tr>
								<td>
									<div class="actions-heading no-top-margin">
										<i>Actions:</i>
									</div>
									<div *ngIf="question['actions']">
										<br>
										<ul class="action-line" *ngFor="let action of question['actions']">
											<li class="file-name">
												{{action['title'] | unescape}}
											</li>
										</ul>
									</div>
									<br>
									<hr data-pdfmake="{&quot;width&quot;:506, &quot;thickness&quot;:0.5}">
									<hr data-pdfmake="{&quot;width&quot;:506, &quot;thickness&quot;:0.5}">
									<div *ngIf="
										!question['actions'] && question.type != 'freeText' && !(question['autoSavedAction'] | keyvalue)?.length && 
										(
											(responses[i].response == 'No' && question['radioOptions']) || 
											(question['radioOptions'] && responses[i].response == question.radioOptions[0].answer && question.radioOptions[0].enforceAction) || 
											(question['radioOptions'] && responses[i].response == question.radioOptions[1].answer && question.radioOptions[1].enforceAction) || 
											(question['radioOptions'] && question['radioOptions']?.length > 2 && responses[i].response == question.radioOptions[2].answer && question.radioOptions[2].enforceAction)
										)"
									>
										<i>Please create an action when <u>{{responses[i].response}}</u> is selected</i>
									</div>
								</td>
								<td></td>
							</tr>
							<tr>
								<td>
									<div class="no-top-margin">
										<div>
											<i class="actions-heading no-top-margin">Reviewed On:</i><span class="action-line">  &nbsp;&nbsp;{{question['pointInTimeReviewDate'] ?    (question['pointInTimeReviewDate']| date :'dd MMM y') : "________________________________"}}</span>
										</div>
									</div>
								</td>
								<td></td>
							</tr>
						</table>
						<br>
						<br>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>