import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree 
} from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { CsaAuthService } from 'src/app/auth/csa-auth.service';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';

@Injectable({
  providedIn: 'root'
})
export class SubfeatureRouteGuard implements CanActivate {
  /**
   * Directs to a valid a route in the application based on the subfeature routes for the current route.
   * This guard navigates to its default route when the user has access to its defined composite subfeature key.
   * It navigates to other valid routes the user has access to.
   * Otherwise, this guard navigates to an error page.
   */

  constructor(
    private authService: CsaAuthService, 
    private featureService: FeatureAccessService, 
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.userChanges$().pipe(
      take(1),
      map((userData) => {
        if (!userData) {
          // Navigate to an error page when no user found.
          this.router.navigate(['/error/unauthorised']);
          return false;
        }

        const params = [];
        for (const key in route.params) {
          params.push(route.params[key]);
        }

        if (!route.data || !route.data['subfeatureRoutes']) {
          // Navigate to an error page when there are no subfeature routes
          this.router.navigate(['/error/unauthorised']);
          return false;
        }

        // Navigate to an error page when there is no default route
        const subfeatureRoutes = route.data['subfeatureRoutes'];
        const defaultSubfeature = Object.keys(subfeatureRoutes).find((subfeatureRoute) => subfeatureRoutes[subfeatureRoute].toLowerCase() === 'default');
        if (!defaultSubfeature) {
          this.router.navigate(['/error/unauthorised']);
          return false;
        }

        // Navigate to the default route when the user has access to it
        if (this.featureService.hasAccess(defaultSubfeature)) {
          return true;
        }

        // Navigate to a subfeature route (other than default) with user access
        let foundRoute;
        Object.keys(subfeatureRoutes).filter((key) => subfeatureRoutes[key].toLowerCase() !== 'default')
          .forEach((compositeKey) => {
            if (!foundRoute && this.featureService.hasAccess(compositeKey)) {
              const route = subfeatureRoutes[compositeKey];
              this.router.navigate([route, ...params]);
              foundRoute = true;
            }
          });

        // Navigate to an error page when no valid routes found
        if (!foundRoute) {
          this.router.navigate(['/error/unauthorised']);
          return false;
        }
      })
    );
  }
}
