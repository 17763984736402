import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { Router } from '@angular/router';
import { CsaAuthService } from '../auth/csa-auth.service';
import { User } from '../models/user';
import { SurveysService } from '../surveys/surveys.service';
import { SwitchDivisionService } from './switch-division.service';

@Component({
  selector: 'app-switch-division',
  templateUrl: './switch-division.component.html',
  styleUrls: ['./switch-division.component.scss']
})
export class SwitchDivisionComponent implements OnInit {
  user: User;

  availableDivisions = [];

  selectedDivision;

  constructor(private auth: CsaAuthService, private surveyService : SurveysService, private router: Router, private switchDivisionService: SwitchDivisionService) { }

  ngOnInit(): void {
    this.auth.userChanges$().pipe(take(1)).subscribe(() => {
      this.user = this.auth.user;
      this.selectedDivision = this.user.division;
      this.getAvailableDivisions();
    });
  }

  getAvailableDivisions() {
    this.surveyService
      .getNextLevel(`?domain=${this.user['domain']}`)
      .subscribe((divisionList) => {
        this.availableDivisions = divisionList['division'];
        console.log(this.availableDivisions);
      });
  }

  public switchDivision() {
    const role = this.user['role'];
    this.user = this.auth.user;

    this.surveyService.getOrgStructure(this.selectedDivision).subscribe((orgStruct) => {
      this.switchDivisionService.getRoles().subscribe((data) => {
        this.auth.setUserOverride('divisionRoles', data[this.selectedDivision]);
        this.auth.setUserOverride('orgStruct', orgStruct);
        this.auth.setUserOverride('role', role);
        this.auth.setUserOverride('super', true);
        this.auth.setUserOverride('division', this.selectedDivision);

        this.router.navigate(['/']);
      });
    });
  }
}
