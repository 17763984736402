<div class="page-container">
  <h1>Material Risk Review Actions</h1>
  <div class="question-options-row">
    <div class="filter-chips">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=selectAssessment.open() color="primary" selected>
            {{selectedAssessment | unescape}} <fa-icon [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #selectAssessment class="optionsDropDown">
          <mat-option>
            <ngx-mat-select-search [clearSearchInput]="false" [searching]="surveysSearching" placeholderLabel="Search by Assessment Title.."  [noEntriesFoundLabel]="noSurveys" name="searchValue" #searchValue ngModel (ngModelChange)="findSurveys($event)"></ngx-mat-select-search>
          </mat-option>
          <mat-option (click)="resetPagination();assessmentFilter('All Assessments')">
            All Assessments
          </mat-option>
          <mat-option [disabled]="searching" *ngFor="let returnedSurvey of surveysReturned" [value]="returnedSurvey" (click)="resetPagination();assessmentFilter(returnedSurvey)">
            {{returnedSurvey | unescape}}
          </mat-option>
        </mat-select>
      </div>

      <ng-container *ngFor="let key of locationsOrdered">
        <div class="chipDrop"  *ngIf="showLocations[key]">
          <mat-chip-list aria-label="Filters">
            <mat-chip (click)="select.open()" selected>
              <span *ngIf="key === 'state' || key === 'region'; else titleLocation">
                {{(selectedLocations[key] ? selectedLocations[key] : (locationStringAll(key) | titlecase)) | unescape}}
              </span>
              <ng-template #titleLocation>
                {{(selectedLocations[key] ? locationString(key) : locationStringAll(key)) | titlecase | unescape}}
              </ng-template>
              <fa-icon [icon]="faSortDown"></fa-icon>
            </mat-chip>
            <mat-select #select class="optionsDropDown">
              <mat-option (click)="resetPagination(); getActionsToShow('', getPreviousLevel(key))">
                {{locationStringAll(key) | titlecase}}
              </mat-option>
              <mat-option *ngFor="let location of availableLocations[key]" (click)="resetPagination(); getActionsToShow(location, key)">
                {{location | unescape}}
              </mat-option>
            </mat-select>
          </mat-chip-list>
        </div>
      </ng-container>

      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=storeSelect.open() color="primary" selected>
            <span *ngIf="selectedStore['Location_Name'] === 'All Stores' && user['division'] !== 'DC'">
              {{selectedStore['Location_Name'] | unescape}}
            </span>
            <span *ngIf="selectedStore['Location_Name'] === 'All Stores' && user['division'] === 'DC'">All DCs</span>
            <span *ngIf="selectedStore['Location_Name'] !== 'All Stores'">
              {{selectedStore['Store_No']}} - {{selectedStore['Location_Name'] | unescape}}
            </span>
            <fa-icon [icon]="faSortDown"></fa-icon>
          </mat-chip>
          <mat-select #storeSelect class="optionsDropDown">
            <mat-option>
              <ngx-mat-select-search [clearSearchInput]="false" [searching]="searching" placeholderLabel="Search by Store Name or Number.."  [noEntriesFoundLabel]="noEntries" name="searchValue" #searchValue ngModel (ngModelChange)="searchForStores($event)"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="user['division'] === 'DC'"
              (click)="resetPagination(); setStore({Store_No: '', Location_Name: 'All Stores'})">All DCs</mat-option>
            <mat-option *ngIf="user['division'] !== 'DC'"
              (click)="resetPagination(); setStore({Store_No: '', Location_Name: 'All Stores'})">All Stores</mat-option>
            <mat-option [disabled]="searching" *ngFor="let returnedStore of storesReturned"
              (click)="resetPagination(); setStore(returnedStore)">
              {{returnedStore['Store_No']}} - {{returnedStore['Location_Name'] | unescape}}
            </mat-option>
          </mat-select>
        </mat-chip-list>
      </div>

      <div class="chipDrop" *ngIf="showReset()">
				<mat-chip-list aria-label="Filters">
					<mat-chip class="clear-chip" (click)="resetFilters()" color="primary" selected>
						<fa-icon class="chip-left-icon" [icon]="faRedoAlt"></fa-icon>
            <span>Clear</span>
					</mat-chip>
				</mat-chip-list>
			</div>
    </div>
  </div>

  <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" [(selectedIndex)]="selectedGroup" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{actionGroup}}" *ngFor="let actionGroup of actionGroups">
          <table mat-table [dataSource]="actionsToShow">
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Action</h4>
              </th>
              <td mat-cell *matCellDef="let action" class="survey-details-link center-align"
                [routerLink]="['/mrr-action-details', action.id]">
                <div class="center-align">
                  <h4> {{action['title'].slice(0, 35) | unescape}}
                    <span *ngIf="(action.title.length > 35)">...</span>
                  </h4>
                  <p [ngClass]="{
                        'orange-text' : action['isDueInWeek'] == true && action['status'] === 'pending',
                        'red-text' : action['overDue'] == true && action['status'] === 'pending'
                        }">
                    Due: {{getDate(action.dueDate).slice(0, -4)}}
                  </p>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Description</h4>
              </th>
              <td mat-cell *matCellDef="let action" class="survey-details-link center-align">
                <div class="left-align">
                  <p> {{action['description'].slice(0, 100) | unescape}}
                    <span *ngIf="(action.title.length > 100)">...</span>
                  </p>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="updates">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Updates</h4>
              </th>
              <td mat-cell *matCellDef="let action" class="survey-details-link center-align"
                [routerLink]="['/mrr-action-details', action.id]">
                <div class="center-align survey-stat">
                  <h1>{{getActionUpdates(action['id'])}}</h1>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="store">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Store</h4>
              </th>
              <td mat-cell *matCellDef="let action" class="survey-details-link center-align"
                [routerLink]="['/mrr-action-details', action.id]">
                <div class="survey-stat template-stat">
                  <h4>{{action['storeID']}}</h4>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="assessment">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Assessment</h4>
              </th>
              <td mat-cell *matCellDef="let action" class="survey-details-link center-align"
                [routerLink]="['/mrr-action-details', action.id]">
                <div class="survey-stat template-stat">
                  <div>{{action['surveyTitle'] | unescape}}</div>
                </div>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns" class="survey-heading-line"></mat-header-row>
            <mat-row *matRowDef="let action; columns: tableColumns;" (click)="saveState = true" [routerLink]="['/mrr-action-details', action.id]"
              class="survey-line survey-line-link"></mat-row>
          </table>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>

          <div *ngIf="actionsToShow.length == 0 && !isLoading">
            <h3 class="none-found-message">
              There are no actions in this section. You can create Actions while completing surveys.
            </h3>
          </div>
        </mat-tab>
      </mat-tab-group>
      <mat-paginator #paginator
          [pageIndex]=pageIndex
          [pageSize]=pageSize
          [length]="totalCount"
          [pageSizeOptions]="[5, 10, 25, 100]"
          [showFirstLastButtons]=false
          (page)="getNext($event)">
      </mat-paginator>
    </div>
  </div>
</div>
