<div class="close-container">
  <span class="info-icon med-grey" (click)="closeMe()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </span>
</div>
<mat-dialog-content>
  <div class="dialog-contents">
    <div class="survey-container">
      <h2 class="left-align">Sign off Details :</h2>

      <div *ngFor="let section of surveyDetails">
        <div class="audit-tool-card question-card" *ngIf="section['signedOff']">
          <div class="p2">
            <div class="question-count">
              <!-- <span class="large-count">{{section['role']}}</span> -->
            </div>

            <div class="question-options-row no-bottom-margin">
              <div class="survey-detail-block">
                <h4>Role :</h4>
                <p>{{ section["role"] | RoleFieldToDisplayString }}</p>
              </div>

              <div class="survey-detail-block">
                <h4>Sign off Date :</h4>
                <p>{{ section["signOffDate"] | date :'dd MMM y' }}</p>

              </div>
            </div>

            <div class="question-options-row no-bottom-margin">
              <div class="survey-detail-block">
                <h4>Email Adddress :</h4>
                <p>{{ section["signedOffBy"] }}</p>
              </div>
            </div>

            <div class="question-options-row no-bottom-margin">
              <div class="survey-detail-block">
                <h4>Sign off Comments :</h4>
                <p class="left">{{ section["comments"] }}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="audit-tool-card question-card"
          *ngIf="!section['signedOff'] && status === 'started'"
        >
          <div class="p2">
            Awaiting for {{ section["role"] | RoleFieldToDisplayString }} sign
            off
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
