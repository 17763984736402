<div class="page-container">

	<div class="store-survey">


		<h1>{{surveyDetails['title'] | unescape}}</h1>


		<h2 class="left-align">Assessment Details:</h2>
		<div class="audit-tool-cards-container">
			<div class="p2 audit-tool-card list-screen-card">


				<div class="question-options-row no-bottom-margin">

					<div class="survey-detail-block">
						<h4>Store:</h4>
						<p>
							{{surveyDetails['storeID']}} - {{surveyDetails['storeName']}}
						</p>
					</div>


					<div class="survey-detail-block">
						<h4>Draft Created:</h4>
						<p>{{ surveyDetails['publishDate'] | date :'dd MMM y' }} </p>
					</div>

					<div class="survey-detail-block" *ngIf="surveyDetails['rating']">
						<h4>Final Rating:</h4>
						<p> {{surveyDetails['rating']}} </p>
					</div>

					<div class="survey-detail-block">
						<h4>Status:</h4>
						<p class="light-green medium-text"> {{surveyDetails['status'] | titlecase}}</p>
					</div>

				</div>

			</div>
		</div>
		<!-- draft report button -->

		<div class="question-options-row" *ngIf="surveyDetails['storeManager'] && surveyDetails['LeadAuditor']">
			<div class="filter-chips">
				<div class="chipDrop">
					<mat-chip-list aria-label="Filters">
						<mat-chip color="primary" selected (click)="editHeader()">
							<span> Draft Report</span>
						</mat-chip>
					</mat-chip-list>
				</div>
			</div>
		</div>

		<!-- final report part -->
		<div *ngIf="surveyDetails['finalReportDate'] && surveyDetails['summaryFindings']">
			<div class="question-options-row">
				<div class="filter-chips">
					<div class="chipDrop">
						<mat-chip-list aria-label="Filters">
							<mat-chip color="primary" selected (click)="finalRate()">
								<span> Final Report</span>
							</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</div>
		</div>

		<!-- sign off comments  part -->
		<div *ngIf="signedOffByAnyRole">
			<div class="question-options-row">
				<div class="filter-chips">
					<div class="chipDrop">
						<mat-chip-list>
							<mat-chip color="primary" selected (click)="OpenSignOffComments()">
								<span> Sign off Details</span>
							</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</div>
		</div>




		<!-- visit note part only available for closed assessments and only can edit by above store users-->
		<div *ngIf="surveyDetails['status'] === 'closed' ">
			<div
				*ngIf="(user['role'] === 'Admin' || user['role'] === 'StateManager' || user['role'] === 'OperationManager' || user['role'] === 'GroupManager') || (viewNotesAvailable)">
				<div class="question-options-row">
					<div class="filter-chips">
						<div class="chipDrop">
							<mat-chip-list>
								<mat-chip color="primary" selected (click)="OpenVisitNotes()">
									<span> Visit Notes</span>
								</mat-chip>
							</mat-chip-list>
						</div>
					</div>
				</div>
			</div>
		</div>


		<form #surveyForm="ngForm">

			<div class="survey-container">
				<h2 class="left-align">Material Risk Review Findings:</h2>
				<div class="question-options-row no-top-margin no-bottom-margin">
					<h3 class="light-green medium-text expand-collapse" (click)="allExpandState = !allExpandState">
						<span *ngIf="allExpandState == false">
							&nbsp;&nbsp;Show All
							<fa-icon [icon]="faAngleDown"></fa-icon>
						</span>
						<span *ngIf="allExpandState == true">
							&nbsp;&nbsp;Hide All
							<fa-icon [icon]="faAngleUp"></fa-icon>
						</span>
						&nbsp;&nbsp;
					</h3>
				</div>

				<mat-accordion class="questions-accordion" multi>
					<mat-expansion-panel [expanded]="allExpandState" #panel hideToggle
						*ngFor="let section of surveyDetails['sectionHeading']; let sectionIndex = index;"
						(opened)="setOpened(sectionIndex)" (closed)="setClosed(sectionIndex)">
						<mat-expansion-panel-header
							*ngIf="(surveyDetails['questions'] | groupQuestions:{sectionHeading: section}).length > 0">
							<mat-panel-title>
								<span class="neg-5-margin hide-on-mobile">
									<svg height="3" width="200">
										<line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
									</svg>
								</span>
								&nbsp;&nbsp;<span>{{section}}</span>&nbsp;&nbsp;<fa-icon
									*ngIf="openItems.indexOf(sectionIndex) === -1" [icon]="faAngleDown"></fa-icon>
								<fa-icon *ngIf="openItems.indexOf(sectionIndex) > -1" [icon]="faAngleUp"></fa-icon>
								&nbsp;&nbsp;
								<span class="neg-5-margin hide-on-mobile">
									<svg height="3" width="200">
										<line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
									</svg>
								</span>
							</mat-panel-title>
						</mat-expansion-panel-header>

						<span
							*ngIf="(surveyDetails['questions'] | groupQuestions:{sectionHeading: section}).length == 0">
							<!-- No findings under this section -->
						</span>

						<div>
							<div
								*ngFor="let question of (surveyDetails['questions'] | groupQuestions:{sectionHeading: section}); let sectionQuestionIndex = index;">
								<div class="audit-tool-card question-card">
									<div class="p2">
										<div class="question-count">
											<span class="large-count">Finding {{sectionQuestionIndex+1}}</span> of
											{{(surveyDetails['questions'] | groupQuestions:{sectionHeading:
											section}).length}}
										</div>

										<h3 class="actions-heading no-top-margin">
											Title
										</h3>
										<div class="action-line">
											{{question['title']}}
										</div>

										<h3 class="actions-heading no-top-margin" *ngIf="question['riskLevel']">
											Risk Level
										  </h3>
										  <div class="action-line" *ngIf="question['riskLevel']">
											{{question['riskLevel']}}
										  </div>

										<h3 class="actions-heading no-top-margin" *ngIf="question['reference']">
											Reference
										</h3>
										<div class="action-line" *ngIf="question['reference']">
											{{question['reference']}}
										</div>

										<div class="centered-fields">
											<div class="multiple-field" [style.--numShown]="2">
												<h3 class="actions-heading no-top-margin">
													Type
												</h3>
												<div class="action-line">
													{{question['MRRtype'] | titlecase}}
												</div>
											</div>
											<div class="multiple-field" [style.--numShown]="2">
												<h3 class="actions-heading no-top-margin">
													Available To
												</h3>
												<div class="action-line">
													{{availableToString(question['availableTo'])}}
												</div>
											</div>
										</div>

										<h3 class="actions-heading no-top-margin" *ngIf="question['rootCause']">
											Root Cause
										</h3>
										<div class="action-line" *ngIf="question['rootCause']">
											{{question['rootCause']}}
										</div>

										<h3 class="actions-heading no-top-margin">
											Detailed Findings
										</h3>
										<div class="action-line">
											{{question['description']}}
										</div>


										<h3 class="actions-heading no-top-margin"
											*ngIf="question['reviewedFrom'] && question['reviewedTo']">
											Period Reviewed
										</h3>
										<div class="action-line"
											*ngIf="question['reviewedFrom'] && question['reviewedTo']">
											<span>
												From {{ question['reviewedFrom']| date :'dd MMM y' }} To
												{{ question['reviewedTo']| date :'dd MMM y' }}
											</span>
										</div>

										<h3 class="actions-heading no-top-margin" *ngIf="question['notes']">
											Notes
										</h3>
										<div class="action-line" *ngIf="question['notes']">
											{{question['notes'] | unescape}}
										</div>

									</div>

									<!-- action part	 -->


									<div class="actions-section">

										<h3 class="actions-heading  no-top-margin"
											*ngIf="question['actions']?.length > 0 || question['autoSavedAction']?.length > 0">
											Actions</h3>
										<div class="action-line" *ngFor="let action of question['actions']">
											<div class="file-name">{{action['title'] | unescape | slice:0:25}}<span
													*ngIf="action['title']?.length > 25">...</span>
											</div>
											<div *ngIf="!action['actionId']" class="right-align">
												{{ action['dueDate'] | date :'dd MMM y' }} </div>

											<div *ngIf="canEdit">
												<div *ngIf="action['actionId']" class="right-align">
													<a (click)="saveForm('All')"
														[routerLink]="['/mrr-action-details', action['actionId']]">
														<span> {{user['role'] === 'NationalView'?
															'View' : 'Edit'}}</span>
														<!-- <span> Edit</span> -->
													</a>
												</div>
											</div>
											<div *ngIf="!canEdit">
												<div *ngIf="action['actionId']" class="right-align">
													<a (click)="saveForm('All')"
														[routerLink]="['/mrr-action-details', action['actionId']]">

														<span> View</span>
													</a>
												</div>
											</div>
										</div>
										<!-- national view user are not able to edit and view unsaved actions -->
										<div *ngIf="user['role'] !== 'NationalView'">
											<div class="action-line"
												*ngFor="let autoSavedAction of question['autoSavedAction']">
												<div class="right-margin" *ngIf="autoSavedAction['title']">
													{{autoSavedAction['title']?.slice(0, 15) | unescape}}
													<span *ngIf="autoSavedAction['title']?.length > 15">...</span>
												</div>
												<div class="right-margin"
													*ngIf="!autoSavedAction['title'] && autoSavedAction['issueDescription']">
													{{autoSavedAction['issueDescription']?.slice(0, 15) | unescape}}
													<span
														*ngIf="autoSavedAction['issueDescription']?.length > 15">...</span>
												</div>
												<div class="right-margin"
													*ngIf="!autoSavedAction['title'] && !autoSavedAction['issueDescription']">
													{{ autoSavedAction['dueDate'] | date :'dd MMM y' }}
												</div>
												<div class="grey-text medium-text"><i>Unsaved</i></div>
												<div class="right-align">
													<a
														(click)="createAction(surveyDetails['title'], surveyDetails['id'], question['title'], question['question_id'], question['shortTitle'], autoSavedAction['autoSavedActionID'])">
														<fa-icon [icon]="faPencilAlt" [spin]="false"></fa-icon>
													</a>
													&nbsp;&nbsp;&nbsp;

												</div>
											</div>
										</div>



										<!-- evidence part -->
										<h3 class="actions-heading no-top-margin"
											*ngIf="question['photos']?.length > 0 || question['uploading']">
											Evidence
										</h3>
										<div class="action-line"
											*ngFor="let photo of question['photos']; let i = index">
											<div class="file-name">
												{{photo['photoName']?.slice(0, 25)}}{{photo['photoName']?.length > 25 ?
												'...' : ''}}
											</div>
											<div class="right-align hide-on-mobile">
												{{photo['fileSize'] | filesize}}
											</div>
											<div class="right-align">
												<a (click)="
												this.user['divisionRoles'][this.user['role']] 
												  > this.user['divisionRoles']['MultiStore'] 
												  ? getAllPhotos(
													question['question_id'], 
													  i, 
													  photo['fileName']
													)
												  :viewPhoto(photo['photoName'], question['question_id'], photo['fileName'])">
												  View
												</a>

											</div>
										</div>

										<div class="action-line" *ngIf="question['uploading'] == true">
											<div class="light-blue">Evidence Uploading...</div>
											<div class="light-blue right-align">
												<span class="padded-icon">
													<fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
												</span>
											</div>
										</div>

										<h3 class="actions-heading  no-top-margin"
											*ngIf="question['pointInTimeReviewDate']">
											Reviewed On
										</h3>
										<div class="action-line" *ngIf="question['pointInTimeReviewDate']">
											<div class="file-name">
												Point In Time Review Date:
												{{ question['pointInTimeReviewDate'] | date :'dd MMM y' }}
											</div>
										</div>
										<!-- evidence part -->

									</div>

									<div class="p2">
										<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (getSurveyResponses(question['question_id']) === 'No' && !question['radioOptions'] )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
											Please Create an Action when {{getSurveyResponses(question['question_id'])}}
											is Selected
										</h5>

										<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (question['radioOptions'] && getSurveyResponses(question['question_id']) == question.radioOptions[0].answer && (question.radioOptions[0].enforceAction) == true )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
											Please Create an Action when {{getSurveyResponses(question['question_id'])}}
											is Selected
										</h5>

										<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (question['radioOptions'] && getSurveyResponses(question['question_id']) == question.radioOptions[1].answer && (question.radioOptions[1].enforceAction) == true )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
											Please Create an Action when {{getSurveyResponses(question['question_id'])}}
											is Selected
										</h5>

										<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (question['radioOptions'] && (question['radioOptions']?.length > 2) && getSurveyResponses(question['question_id']) == question.radioOptions[2].answer && (question.radioOptions[2].enforceAction) == true )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
											Please Create an Action when {{getSurveyResponses(question['question_id'])}}
											is Selected
										</h5>
									</div>

									<h5 class="med-grey" *ngIf="(question['autoSavedAction'] | keyvalue)?.length > 0">
										Please
										Complete the Above Action</h5>

								</div>
							</div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>


			<div class="submit-button-container center-align" *ngIf="canEdit">

				<div *ngIf="signoffbyRole">
					<button class="csa-button-solid survey-submit" (click)="signoffMrr()">
						<fa-icon class="chip-left-icon" [icon]="faPaperPlane"></fa-icon>
						<span>
							Sign off
						</span>
					</button>
				</div>


			</div>
		</form>

	</div>
</div>