import { ActivatedRoute, Router } from '@angular/router';
import {
  Component, OnInit, ViewChild, ElementRef 
} from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { CreateSurveyDialogComponent } from '../create-survey-dialog/create-survey-dialog.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

import { LoaderService } from '../../common-services/loader.service';
import { StateService } from '../../common-services/state.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-above-store-more-surveys',
  templateUrl: './above-store-more-surveys.component.html',
  styleUrls: ['./above-store-more-surveys.component.scss'],
})
export class AboveStoreMoreSurveysComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faPlus = faPlus;

  date = moment();

  filter: string = '';

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  selectedGroup: number = 0;

  surveyGroups: string[] = ['Active', 'Closed'];

  surveysToShow: any[] = [];

  tableColumns: string[];

  totalCount: number = 0;

  user: User = {};

  saveState: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private surveyCompletionData: LoaderService,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private state: StateService,
    private surveyService : SurveysService
  ) {}

  ngOnInit(): void {
    // this.surveyCompletionData.currentMessage.subscribe(message => this.surveyCompletion = message);
    // this.getSelectedGroup(this.actRoute.snapshot.paramMap.get('status'));
    this.user = this.csaAuth.user;
    if (this.state.state && this.state.origin == 'above-store-more-surveys') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }
    }
    this.tableColumns = ['survey', 'dueDate', '%Complete', 'actions'];
    this.getInitialFilters();
  }

  ngAfterViewInit() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    label.innerHTML = `${String(this.index + 1)} to ${this.pageSize} of Many`;
  }

  ngOnDestroy() {
    this.setState();
  }

  setState() {
    if (this.saveState) {
      this.state.origin = 'above-store-more-surveys';
      this.state.state = {
        filter: this.filter,
        hasNext: this.hasNext,
        index: this.index,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedGroup: this.selectedGroup,
        surveysToShow: this.surveysToShow,
        tableColumns: this.tableColumns,
        totalCount: this.totalCount,
        user: this.user
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  surveyDetails(message: string) {
    this.surveyCompletionData.changeMessage(message);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;
    this.getSurveysToShow();
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getSurveysToShow();
  }

  getInitialFilters() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.user['role'] == 'GroupManager') {
          if (this.user['division'] == 'Metro') {
            resolve(
              `&groupID=${ 
                this.user['groupNo']
              }&state=${
                this.user['state']}`
            );
          } else if (this.user['domain'] == 'WoolworthsNZ') {
            resolve(
              `&groupID=${ 
                this.user['groupNo']
              }&zone=${
                this.user['zone']}`
            );
          } else {
            resolve(
              `&groupID=${ 
                this.user['groupNo']
              }&zone=${
                this.user['zone']
              }&state=${
                this.user['state']}`
            );
          }
        } else if (this.user['role'] == 'OperationManager') {
          if (this.user['division'] == 'Metro') {
            resolve(`&state=${this.user['state']}`);
          } else if (this.user['domain'] == 'WoolworthsNZ') {
            resolve(
              `&zone=${ 
                this.user['zone']}`
            );
          } else {
            resolve(
              `&zone=${ 
                this.user['zone']
              }&state=${
                this.user['state']}`
            );
          }
        } else if (this.user['role'] == 'StateManager') {
          resolve(`&state=${this.user['state']}`);
        } else if (this.user['role'] == 'RegionManager') {
          resolve(`&region=${this.user['region']}`);
        } else if (this.user['role'] == 'AreaManager') {
          resolve(
            `&area=${ 
              this.user['area']
            }&region=${
              this.user['region']}`
          );
        } else {
          resolve('');
        }
      });
    }).then((tempFilter: string) => {
      this.filter = tempFilter;
      this.getSurveysToShow();
    });
  }

  getSurveysToShow() {
    if (this.selectedGroup == 1) {
      this.surveyService
        .getSurveys(
          '/above-store',
          `${this.user['division']}&status=closed${this.filter}`,
          '',
          `/${this.index}`,
          `/${this.pageSize}`
        )
        .subscribe((surveysData) => {
          this.surveysToShow = surveysData['surveys'];
          this.hasNext = surveysData['has_next'];
          this.getPagination();
        });
    } else if (this.selectedGroup == 0) {
      this.surveyService
        .getSurveys(
          '/above-store',
          `${this.user['division']}&status=published${this.filter}`,
          '',
          `/${this.index}`,
          `/${this.pageSize}`
        )
        .subscribe((surveysData) => {
          this.surveysToShow = surveysData['surveys'];
          this.hasNext = surveysData['has_next'];
          this.getPagination();
        });
    }
  }
  
  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }
}
