import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  EventEmitter,
  NgZone
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';

import {
  faSave,
  faPencilAlt,
  faTimes,
  faPaperPlane,
  faCircleNotch,
  faCamera,
  faPaperclip

} from '@fortawesome/free-solid-svg-icons';
import {
  FormGroup,
  NgForm,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import * as moment from 'moment';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Console } from 'console';
import Compressor from 'compressorjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { DrillDownFilterPipe } from '../../custom-pipes/drill-down-filter.pipe';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { LoaderService } from '../../common-services/loader.service';
import { PhotoPreviewComponent } from '../../surveys/photo-preview/photo-preview.component';
import { User } from '../../models/user';
import { MrrSurveysService } from '../mrr-surveys.service';

@Component({
  selector: 'app-mrr-header-dialog',
  templateUrl: './mrr-header-dialog.component.html',
  styleUrls: ['./mrr-header-dialog.component.scss'],
  providers: [
    DrillDownFilterPipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class MrrHeaderDialogComponent implements OnInit {
  @ViewChild('createSurveyForm') createSurveyForm: NgForm;

  mrrForm: FormGroup;

  today = moment();

  survey = {};

  currentYear = new Date().getDate();

  surveyID = '';

  faTimes = faTimes;

  faCircleNotch = faCircleNotch;

  faCamera = faCamera;

  faPaperclip = faPaperclip;

  response = {};

  // orgArray: any[];
  dateToday = new Date();

  maxDate = new Date();

  minfromDate = new Date();

  faSave = faSave;

  faPaperPlane = faPaperPlane;

  minDate = new Date();

  completionMinDate = new Date();

  completionMaxDate = new Date();

  uploading: boolean = false;

  photos = [];

  user: User;

  ratings = ['Effective', 'Substantially Effective', 'Partially Effective', 'Ineffective', 'Non Rated'];

  uploadDocument: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private actRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private loader: LoaderService,
    private zone: NgZone,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private dialog: MatDialog,
    private mrrService :MrrSurveysService,
    private surveyService : SurveysService,
    private dialogRef: MatDialogRef<MrrHeaderDialogComponent>
  ) { }

  // Date format comaprisons
  public onDateChange(event: MatDatepickerInputEvent<Date>): void {
    const dates = event.value.toString();
    this.maxDate = new Date(dates);
    console.log('onDateChange hitting..........', this.response['toDate']);
    this.maxDate.setDate(this.maxDate.getDate() + 7);
  }

  ngOnInit(): void {
    this.user = this.csaAuth.user;

    this.response = this.data.survey;
    if (this.response['files']) {
      if (this.response['files']['draft']) this.photos = this.data.survey['files']['draft'];
    } else {
      this.photos = [];
    }
    if (this.response['fromDate']) {
      this.maxDate = new Date(this.response['fromDate']);
      this.maxDate.setDate(this.maxDate.getDate() + 7);
      console.log('ngOninit form dates /////////', this.maxDate);
      this.dateToday = this.response['toDate'];
    } else {
      this.maxDate.setFullYear(this.maxDate.getFullYear() + 10);
      this.response['dueDate'] = new Date();
      this.response['dueDate'].setDate(this.minDate.getDate() + 42);
    }

    this.mrrForm = new FormGroup({
      storeManager: new FormControl('', {
        validators: [
          this.specialCharactersValidator.bind(this),
          this.emptyStringValidator.bind(this),
        ],
      }),
      LeadAuditor: new FormControl('', {
        validators: [
          this.specialCharactersValidator.bind(this),
          this.emptyStringValidator.bind(this),
        ],
      }),
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl('', [Validators.required]),
      dueDate: new FormControl('', [Validators.required]),
    });
  }

  sendSurvey(form, value) {
    console.log('before form resylts', this.response);
    const draft = {};
    draft['id'] = this.response['id'];
    draft['storeManager'] = value.storeManager;
    draft['rating'] = value.rating;
    draft['LeadAuditor'] = value.LeadAuditor;
    draft['fromDate'] = moment(value.fromDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]).format();
    draft['toDate'] = moment(value.toDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]).format();
    draft['dueDate'] = moment(value.dueDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]).format();
    console.log('he form resylts', this.response);
    console.log('draft', draft);
    // same api is used for creating a finding and add header detailes in MRR
    
    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(draft, ['questions']);
    this.mrrService.createFinding(requestJSONPayload).subscribe(
      (reply) => {
        if (reply['status'] == 200) {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Draft Header Saved',
              message:
              'Your Draft Header has been saved.',
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });

          this.dialogRef.close({ survey: this.response });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Cannot Save Draft Header',
              message:
              `Please email ${this.user['supportEmail']} for support`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
          this.dialogRef.close({ survey: [] });
        }
      },
      (err) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot Save Draft Header',
            message:
              `Please email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(err);
      }
    );
  }

  saveNotes(value) {
    console.log('response', value, this.response, this.dateToday);
    if (value.storeManager) {
      this.response['storeManager'] = value.storeManager;
    }
    if (value.rating) {
      this.response['rating'] = value.rating;
    }
    if (value.LeadAuditor) {
      this.response['LeadAuditor'] = value.LeadAuditor;
    }
    if (value.fromDate) {
      this.response['fromDate'] = moment(value.fromDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
    }
    if (value.toDate) {
      this.response['toDate'] = moment(value.toDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
      this.dateToday = this.response['toDate'];
    }
    if (value.dueDate) {
      this.response['toDate'] = this.dateToday;
      this.response['dueDate'] = moment(value.dueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
    }
  }

  getDate(date) {
    const newDate = moment(date, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]).format('DD MMM YYYY');
    return newDate;
  }

  closeMe() {
    this.dialogRef.close();
  }

  emptyStringValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    if (control.value && control.value.trim().length === 0) {
      return { whitespace: true };
    }
    return null;
  }

  specialCharactersValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const re = /^[a-zA-Z0-9 ]+$/;
    if (control.value && !re.test(control.value)) {
      return { special: true };
    }
    return null;
  }

  uploadDoc($event, question, survey) {
    if (this.response['storeManager']  
  && this.response['LeadAuditor'] 
   && this.response['fromDate']
   && this.response['toDate']) {
      this.uploading = true;
      const photoName = $event.target.files[0].name;
      const goodPhotoType = $event.target.files[0].name
        .toLowerCase()
        .match(/\.(pdf|jpg|png|gif|tiff|jpeg|bmp|raw)/g);

      const PDFTest = $event.target.files[0].name.toLowerCase().match(/\.(pdf)/g);
      if (goodPhotoType == null) {
        this.displayUploadErrorDoc(
          'This file is not an image or PDF',
          'Please select an image or PDF file to attach.',

        );
      } else if (PDFTest == null) {
        this.compressPhoto($event.target.files[0], question).subscribe(
          (compressedPhoto) => {
            if (typeof compressedPhoto === 'undefined') {
              this.displayUploadErrorDoc(
                'Error Compressing File',
                `Please try a different file or email ${
                  this.user['supportEmail']
                } for support`,

              );
            } else if (compressedPhoto['size'] > 10000000) {
              this.displayUploadErrorDoc(
                'This File Is Too Big',
                'Please upload a smaller file',

              );
            } else {
              const action = this.mrrService
                .uploadDocs(
                  compressedPhoto,
                  survey,
                  question,
                  this.response['storeID'],
                  `${compressedPhoto['size']}`
                )
                .subscribe(
                  (response) => {
                    if (!response[0] || response[0]['status'] != 200) {
                      let message;
                      if (response[0]['status'] != 200) {
                        message = `There was an error when sending your file. Please email ${ 
                          this.user['supportEmail']
                        } for support`;
                      } else {
                        message = `No response from server. Please email ${ 
                          this.user['supportEmail']
                        } for support`;
                      }
                      this.displayUploadErrorDoc(
                        "Couldn't Add File",
                        message,

                      );
                    } else if (response[0]['message'] == 'OK') {
                      this.uploadDocument = true;
                      this.getDocThisQuestion({
                        photoName: response[0]['name'],
                        fileName: response[0]['fileID'],
                        fileSize: compressedPhoto['size'],
                      });
                    }
                  },
                  (error) => {
                    this.displayUploadErrorDoc(
                      "Couldn't Add File",
                      `Please email ${
                        this.user['supportEmail']
                      } for support`

                    );
                  }
                );
            }
          }
        );
      } else if ($event.target.files[0]['size'] > 10000000) {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'This File Is Too Big',
            message: 'Please upload a smaller file',
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
        setTimeout(() => {
          this.uploading = false;
        }, 10);
      } else {
        const action = this.mrrService
          .uploadDocs(
            $event.target.files[0],
            survey,
            question,
            this.response['storeID'],
            $event.target.files[0]['size']
          )
          .subscribe(
            (response) => {
              if (!response[0] || response[0]['status'] != 200) {
                let message;
                if (response[0]['status'] != 200) {
                  message = `There was an error when sending your file. Please email ${ 
                    this.user['supportEmail']
                  } for support`;
                } else {
                  message = `No response from server. Please email ${ 
                    this.user['supportEmail']
                  } for support`;
                }
                this.displayUploadErrorDoc("Couldn't Add File", message);
              } else {
                this.uploadDocument = true;
                this.getDocThisQuestion({
                  photoName: response[0]['name'],
                  fileName: response[0]['fileID'],
                  fileSize: $event.target.files[0]['size'],
                });
                this.cd.detectChanges();
              }
            },
            (error) => {
              this.displayUploadErrorDoc(
                "Couldn't Add File",
                `Please email ${ 
                  this.user['supportEmail']
                } for support`,

              );
            }
          );
      }
    } else {
      this.displayUploadErrorDoc(
        "Couldn't Add File",
        'Please complete all the mandatory fields to upload the Draft Report',
      );
    }
  }

  displayUploadErrorDoc(heading, message) {
    this.dialog.open(MessagesComponent, {
      data: {
        heading,
        message,
        closeText: 'Ok',
      },
      backdropClass: 'dialog-backdrop',
    });
    setTimeout(() => {
      this.uploading = false;
    }, 10);
  }

  compressPhoto(file, question) {
    const emitter = new EventEmitter<Blob>();

    const compression = new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: 1000000,
      success(result) {
        emitter.emit(result);
      },
      error(err) {
        emitter.emit();
      },
    });
    return emitter.asObservable();
  }

  getDocThisQuestion(photo) {
    setTimeout(() => {
      // if(!this.response['files']){
      //   if(!this.response['files']['draft'] )
      //         this.photos = [];
      // }
      this.photos.push(photo);
     
      this.uploading = false;
    }, 10);
    this.cd.detectChanges();
  }

  viewfinalDoc(photoName, question, fileName, showRemove) {
    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.response['id']
      }-${
        question
      }-${
        this.response['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        console.log(photoData);
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewfinalDoc(photoName, question, fileName, showRemove);
            }
          });
        } else if (Object.keys(photoData).length > 0) {
          this.zone.run(() => {
            const photoDialog = this.dialog.open(PhotoPreviewComponent, {
              data: {
                photoURL: photoData[photo],
                photoName,
                showRemove,
                documentType: 'Draft Report'
              },
              backdropClass: 'dialog-backdrop',
              minWidth: '70%',
              maxWidth: '90vw',
              height: 'auto',
            });

            photoDialog.afterClosed().subscribe((result) => {
              if (result == 'delete') this.removePhoto(photoData, question, photo);
            });
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
            `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewfinalDoc(photoName, question, fileName, showRemove);
          }
        });
      }
    );
  }

  removePhoto(src, question, photo) {
    this.mrrService
      .removeDoc(photo, src, this.response['id'], question)
      .subscribe(
        (response) => {
          this.uploadDocument = true;
          this.removePhotoFromQ(photo);
          this.cd.detectChanges();
        },
        (error) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Remove File",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
  }

  removePhotoFromQ(fileName) {
    for (let j = 0; j < this.photos.length; j++) {
      if (
        this.photos[j]['photoName'] == fileName
        || this.photos[j]['fileName'] == fileName
      ) {
        this.photos.splice(j, 1);
        setTimeout(() => {
          this.uploading = false;
        }, 10);
      }
    }
  }
}
