import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgModule } from '@angular/core';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OrderModule } from 'ngx-order-pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Routes, RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';
import { AboveStoreLandingComponent } from './landing-pages/above-store-landing/above-store-landing.component';
import { AboveStoreMoreSurveysComponent } from './surveys/above-store-more-surveys/above-store-more-surveys.component';
import { AccessManagementComponent } from './surveys/access-management/access-management.component';
import { AccessManagementDialogComponent } from './surveys/access-management-dialog/access-management-dialog.component';
import { StoreDashboardComponent } from './surveys/store-dashboard/store-dashboard.component';
import { StoreDashboardDialogComponent } from './surveys/store-dashboard-dialog/store-dashboard-dialog.component';
import { ActionDetailsComponent } from './surveys/action-details/action-details.component';
import { ActionsDrillDownComponent } from './surveys/actions-drill-down/actions-drill-down.component';
import { AdminLandingComponent } from './landing-pages/admin-landing/admin-landing.component';
import { AdminReportsComponent } from './surveys/admin-reports/admin-reports.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CreateActionDialogComponent } from './surveys/create-action-dialog/create-action-dialog.component';
import { CreateMRRDialogComponent } from './mrr-surveys/create-mrr-dialog/create-mrr-dialog.component';
import { CreateMRRFindingDialogComponent } from './mrr-surveys/create-mrr-finding-dialog/create-mrr-finding-dialog.component';
import { CreateSurveyDialogComponent } from './surveys/create-survey-dialog/create-survey-dialog.component';
import { CsaAuthModule } from './auth/csa-auth.module';
import { DrillDownFilterPipe } from './custom-pipes/drill-down-filter.pipe';
import { ErrorPageComponent } from './surveys/error-page/error-page.component';
import { FilterPipe } from './custom-pipes/filter.pipe';
import { GroupQuestionsPipe } from './custom-pipes/group-questions.pipe';
import { HeaderBarComponent } from './shared/header-bar/header-bar.component';
import { httpInterceptorProviders } from './http-interceptors/index';
import { LoaderService } from './common-services/loader.service';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { LoadTemplateDialogComponent } from './surveys/load-template-dialog/load-template-dialog.component';
import { MessagesComponent } from './surveys/messages/messages.component';
import { MoreActionsComponent } from './surveys/more-actions/more-actions.component';
import { MoreEvidenceComponent } from './surveys/more-evidence/more-evidence.component';
import { MoreOntheflySurveysComponent } from './adhoc-surveys/more-onthefly-surveys/more-onthefly-surveys.component';
import { MoreRecurringSurveysComponent } from './recurring-survey/more-recurring-surveys/more-recurring-surveys.component';
import { MoreSurveysAdminComponent } from './surveys/more-surveys-admin/more-surveys-admin.component';
import { MoreSurveysComponent } from './surveys/more-surveys/more-surveys.component';
import { MoreTemplatesComponent } from './surveys/more-templates/more-templates.component';
import { MrrDraftDetailsComponent } from './mrr-surveys/mrr-draft-details/mrr-draft-details.component';
import { MrrStoreSurveyComponent } from './mrr-surveys/mrr-store-survey/mrr-store-survey.component';
import { MrrSurveyDetailsComponent } from './mrr-surveys/mrr-survey-details/mrr-survey-details.component';
import { OnceOffSurveyDetailsComponent } from './surveys/once-off-survey-details/once-off-survey-details.component';
import { OnTheFlySurveyDetailsComponent } from './adhoc-surveys/on-the-fly-survey-details/on-the-fly-survey-details.component';
import { PhotoCarouselComponent } from './surveys/photo-carousel/photo-carousel.component';
import { PhotoPreviewComponent } from './surveys/photo-preview/photo-preview.component';
import { RecurringSurveyDetailsComponent } from './recurring-survey/recurring-survey-details/recurring-survey-details.component';
import { ResultsTableComponent } from './surveys/results-table/results-table.component';
import { StoreLandingComponent } from './landing-pages/store-landing/store-landing.component';
import { StoreSelectionDialogComponent } from './surveys/store-selection-dialog/store-selection-dialog.component';
import { StoreSurveyComponent } from './surveys/store-survey/store-survey.component';
import { SurveyDetailsComponent } from './surveys/survey-details/survey-details.component';
import { TemplateDetailsComponent } from './surveys/template-details/template-details.component';
import { ThankYouComponent } from './surveys/thank-you/thank-you.component';
import { SortPipe } from './custom-pipes/sort.pipe';
import { NumKeysPipe } from './custom-pipes/num-keys.pipe';
import { RemoveWhiteSpacePipe } from './custom-pipes/remove-white-space.pipe';
import { SupportLandingComponent } from './landing-pages/support-landing/support-landing.component';
import { MrrHeaderDialogComponent } from './mrr-surveys/mrr-header-dialog/mrr-header-dialog.component';
import { MoreMrrSurveysComponent } from './mrr-surveys/more-mrr-surveys/more-mrr-surveys.component';
import { FinalReportHeaderComponent } from './mrr-surveys/final-report-header/final-report-header.component';
import { MrrStoreDetailsComponent } from './mrr-surveys/mrr-store-details/mrr-store-details.component';
import { MrrActionsDrillDownComponent } from './mrr-surveys/mrr-actions-drill-down/mrr-actions-drill-down.component';
import { MrrMoreActionsComponent } from './mrr-surveys/mrr-more-actions/mrr-more-actions.component';
import { MrrStoreDraftViewDialogComponent } from './mrr-surveys/mrr-store-draft-view-dialog/mrr-store-draft-view-dialog.component';
import { MrrStoreFinalViewDialogComponent } from './mrr-surveys/mrr-store-final-view-dialog/mrr-store-final-view-dialog.component';
import { CreateMrrActionDialogComponent } from './mrr-surveys/create-mrr-action-dialog/create-mrr-action-dialog.component';
import { ThankYouMrrComponent } from './mrr-surveys/thank-you-mrr/thank-you-mrr.component';
import { MrrAboveStoreSurveyComponent } from './mrr-surveys/mrr-above-store-survey/mrr-above-store-survey.component';
import { MrrActionDetailsComponent } from './mrr-surveys/mrr-action-details/mrr-action-details.component';
import { SignOffDialogComponent } from './mrr-surveys/sign-off-dialog/sign-off-dialog.component';
import { MrrStoreSignoffCommentsDialogComponent } from './mrr-surveys/mrr-store-signoff-comments-dialog/mrr-store-signoff-comments-dialog.component';
import { RoleFieldToDisplayStringPipe } from './custom-pipes/roleFieldToDisplayString.pipe';
import { VisitNotesDialogComponent } from './mrr-surveys/visit-notes-dialog/visit-notes-dialog.component';
import { DeveloperHandbookComponent } from './developer-handbook/developer-handbook.component';
import { SharedModule } from './shared/shared.module';
import { NavigationComponent } from './navigation/navigation.component';
import { UserMenuComponent } from './navigation/components/user-menu/user-menu.component';
import { MultiStoreSelectionComponent } from './multi-store-selection/multi-store-selection.component';
import { SwitchDivisionComponent } from './switch-division/switch-division.component';
import { ToDoListComponent } from './to-do-list/to-do-list.component';
import { ToDoListItemCardComponent } from './to-do-list/components/to-do-list-item-card/to-do-list-item-card.component';
import { MobileHeaderMenuComponent } from './navigation/components/mobile-header-menu/mobile-header-menu.component';
import { LocationSelectionComponent } from './shared/location-selection/location-selection.component';
import { AccessCheckPipe } from './custom-pipes/access-check.pipe';

const routes: Routes = [];

@NgModule({
  declarations: [
    AboveStoreLandingComponent,
    AboveStoreMoreSurveysComponent,
    AccessManagementComponent,
    AccessManagementDialogComponent,
    StoreDashboardComponent,
    StoreDashboardDialogComponent,
    ActionDetailsComponent,
    ActionsDrillDownComponent,
    AdminLandingComponent,
    AdminReportsComponent,
    AppComponent,
    CreateActionDialogComponent,
    CreateMRRDialogComponent,
    CreateMRRFindingDialogComponent,
    CreateSurveyDialogComponent,
    DrillDownFilterPipe,
    ErrorPageComponent,
    FilterPipe,
    GroupQuestionsPipe,
    HeaderBarComponent,
    LoadingSpinnerComponent,
    LoadTemplateDialogComponent,
    MessagesComponent,
    MoreActionsComponent,
    MoreEvidenceComponent,
    MoreOntheflySurveysComponent,
    MoreRecurringSurveysComponent,
    MoreSurveysAdminComponent,
    MoreSurveysComponent,
    MoreTemplatesComponent,
    MrrDraftDetailsComponent,
    MrrStoreSurveyComponent,
    MrrSurveyDetailsComponent,
    OnceOffSurveyDetailsComponent,
    OnTheFlySurveyDetailsComponent,
    PhotoCarouselComponent,
    PhotoPreviewComponent,
    RecurringSurveyDetailsComponent,
    ResultsTableComponent,
    StoreLandingComponent,
    StoreSelectionDialogComponent,
    StoreSurveyComponent,
    SurveyDetailsComponent,
    TemplateDetailsComponent,
    ThankYouComponent,
    SortPipe,
    NumKeysPipe,
    RemoveWhiteSpacePipe,
    AccessCheckPipe,
    SupportLandingComponent,
    MrrHeaderDialogComponent,
    MoreMrrSurveysComponent,
    FinalReportHeaderComponent,
    MrrStoreDetailsComponent,
    FinalReportHeaderComponent,
    MrrActionsDrillDownComponent,
    MrrMoreActionsComponent,
    MrrStoreDraftViewDialogComponent,
    MrrStoreFinalViewDialogComponent,
    CreateMrrActionDialogComponent,
    ThankYouMrrComponent,
    MrrAboveStoreSurveyComponent,
    MrrActionDetailsComponent,
    SignOffDialogComponent,
    MrrStoreSignoffCommentsDialogComponent,
    RoleFieldToDisplayStringPipe,
    VisitNotesDialogComponent,
    DeveloperHandbookComponent,
    NavigationComponent,
    UserMenuComponent,
    MultiStoreSelectionComponent,
    SwitchDivisionComponent,
    ToDoListComponent,
    ToDoListItemCardComponent,
    MobileHeaderMenuComponent,
  ],
  imports: [
    SharedModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CsaAuthModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxFilesizeModule,
    NgxMatSelectSearchModule,
    OrderModule,
    PdfViewerModule,
    ReactiveFormsModule,
    SwiperModule,
    SharedModule
  ],
  providers: [
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    httpInterceptorProviders,
    LoaderService,
    SortPipe,
    RoleFieldToDisplayStringPipe,
    RemoveWhiteSpacePipe,
    DrillDownFilterPipe,
    FilterPipe,
    GroupQuestionsPipe,
    NumKeysPipe,
    RoleFieldToDisplayStringPipe,
    AccessCheckPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
