import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SwitchDivisionService {
  private rolesUrl = `${environment.backEndUrl}/roles`;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    }),
  };

  constructor(private httpClient: HttpClient) { }

  getRoles(): Observable<object> {
    return this.httpClient.get<object>(this.rolesUrl, this.httpOptions).pipe(
      catchError((err) => {
        console.log('Error in getting roles data.');
        console.log(err.message);
        return of(null);
      })
    );
  }
}
