import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading = new BehaviorSubject(true);

  private averageCompletion = new BehaviorSubject('0');

  currentMessage = this.averageCompletion.asObservable();

  constructor() { }

  show(location) {
    if (
      location.indexOf('/storage/upload/files') == -1
      && location.indexOf('/list') == -1
      && location.indexOf('/search') == -1
      && location.indexOf('/survey/search') == -1
      && location.indexOf('/drillDown') == -1
      && location.indexOf('/generateUrl') == -1
    ) {
      this.isLoading.next(true);
    }
  }

  hide(location?) {
    this.isLoading.next(false);
  }

  changeMessage(message: string) {
    this.averageCompletion.next(message);
  }

}
